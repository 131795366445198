import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import DeleteIcon from "../../assets/imgs/dlt-icn.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import PlusIcon from "../../assets/imgs/pls-icn.png";
import PrImg from "../../assets/imgs/pr-img.png";
import PrIconDel from "../../assets/imgs/pr-del.png";
import PrIconEdit from "../../assets/imgs/pr-edit.png";
import Pagination from "../../components/Pagi/Pagination";
import axios from "axios";
import { baseUrl } from "../../utils/Function";
import ModalDeleteIcon from "../../assets/imgs/modal-delete.png";
import ModalCancelIcon from "../../assets/imgs/modal-cancel.png";

function Product() {
  // states

  const [show, setShow] = useState(false);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const productsToDisplay = product.reverse().slice(startIndex, endIndex);
  const totalPages = Math.ceil(product.reverse().length / itemsPerPage);

  //Api integrate
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      console.log(token, "token check");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      axios
        .get(`${baseUrl}/get-all-rating-products`)

        .then((response) => {
          setProduct(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } else {
      console.error("No token available. Handle unauthorized access here.");
      setLoading(false);
    }
  }, []);

  const handleDeleteAnyway = () => {
    if (productIdToDelete !== null) {
      axios
        .delete(`${baseUrl}/delete-product/${productIdToDelete}`)
        .then((response) => {
          const updatedProducts = product.filter(
            (p) => p.product_id !== productIdToDelete
          );
          setProduct(updatedProducts);
          handleClose();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // functions and variables
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (productId) => {
    setProductIdToDelete(productId); // Set the product to delete
    setShow(true);
  };

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Product</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                <Link to="/ProductAdd">
                  <img src={PlusIcon} alt="" />
                </Link>
              </div>
              <div className="right-icn">
                {/* <img onClick={handleShow} src={ DeleteIcon} alt="" /> */}
              </div>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="d-flex justify-content-between">
                <div className="order-list-heading inner-left-head d-flex align-items-center">
                  <img src={BulletedIcon} alt="" />
                  <h4>Product List</h4>
                </div>
                <div className="main-page-box-inner add-pt-btn">
                  <Link to="/ProductAdd">Add Product</Link>
                </div>
              </div>
              {/* <div className="main-table-parent-rsp-master">
                <div className="main-table-parent-rsp">
                  <div className="grid-manage-parent-sup">
                    <div
                      className="grid-manage-parent"
                      key={product.product_id}
                    >
                      <div className="pr-table brdr-right-pr text-center">
                        <input key={product.product_id} type="checkbox" />
                      </div>
                      <div className="pr-table brdr-right-pr text-center">
                        <p>Images</p>
                      </div>
                      <div className="pr-table brdr-right-pr text-indent-cust">
                        <p>Product Name</p>
                      </div>
                      <div className="pr-table brdr-right-pr text-indent-cust">
                        <p>Price</p>
                      </div>
                      <div className="pr-table text-indent-cust">
                        <p>Action</p>
                      </div>
                    </div>
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      productsToDisplay.map((product) => (
                        <div className="grid-manage-parent grid-manage-parent-rev grid-cust-resp">
                          <div className="pr-table brdr-right-pr text-center">
                            <input type="checkbox" />
                          </div>
                          <div className="pr-table brdr-right-pr text-center align-items-center">
                            <img
                              src={`${baseUrl}/${product.product_image}`}
                              alt=""
                            />
                          </div>
                          <div className="pr-table brdr-right-pr text-indent-cust">
                            <p>{product.product_name}</p>
                          </div>
                          <div className="pr-table brdr-right-pr text-indent-cust">
                            <p>
                              <span className="line-through-text">
                                ${product.regular_price}
                              </span>
                              <span className="norm-price">
                                ${product.sale_price}
                              </span>
                            </p>
                          </div>
                          <div className="pr-table text-indent-cust">
                            <div className="dlt-edit">
                              <img
                                className="pr-table-del-img"
                                onClick={() => handleShow(product.product_id)}
                                src={PrIconDel}
                                alt=""
                              />
                              <Link to={`/ProductAdd/${product.product_id}`}>
                                <img src={PrIconEdit} alt="" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div> */}
              <div className="main-table-parent-rsp-master">
                <div className="main-table-parent-rsp">
                  <table class="table product-tablle-tag table-bordered table-responsive mt-5">
                    <thead>
                      <tr key={product.product_id}>
                        <th scope="col">
                          <div className="pr-table brdr-right-pr text-center">
                            <input key={product.product_id} type="checkbox" />
                          </div>
                        </th>
                        <th scope="col">
                          {" "}
                          <div className="pr-table brdr-right-pr text-center">
                            <p>Images</p>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="pr-table brdr-right-pr text-indent-cust">
                            <p>Product Name</p>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="pr-table brdr-right-pr text-indent-cust">
                            <p>Price</p>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="pr-table text-indent-cust">
                            <p>Action</p>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <div>Loading...</div>
                      ) : (
                        productsToDisplay.map((product) => (
                          <tr>
                            <th scope="row">
                              <div className="pr-table brdr-right-pr text-center">
                                <input type="checkbox" />
                              </div>
                            </th>
                            <td>
                              <div className="pr-table brdr-right-pr text-center align-items-center">
                                <img
                                  src={`${baseUrl}/${product.product_image}`}
                                  alt=""
                                />
                              </div>
                            </td>
                            <td>
                              <div className="pr-table brdr-right-pr text-indent-cust">
                                <p>{product.product_name}</p>
                              </div>
                            </td>
                            <td>
                              <div className="pr-table brdr-right-pr text-indent-cust">
                                <p>
                                  <span className="line-through-text">
                                    ${product.regular_price}
                                  </span>
                                  <span className="norm-price">
                                    ${product.sale_price}
                                  </span>
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="pr-table text-indent-cust">
                                <div className="dlt-edit">
                                  <img
                                    className="pr-table-del-img"
                                    onClick={() =>
                                      handleShow(product.product_id)
                                    }
                                    src={PrIconDel}
                                    alt=""
                                  />
                                  <Link
                                    to={`/ProductAdd/${product.product_id}`}
                                  >
                                    <img src={PrIconEdit} alt="" />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="main-div10 d-flex justify-content-between">
                <div className="pagi">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                  />{" "}
                </div>
                <div className="txt-right-pagi ">
                  <p>Showing 1 to 10 of 90 (9 Pages)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="DeleteModal" show={show} onHide={handleClose}>
        <div className="delete-modal-parent">
          <div className="modal-close-button text-end">
            <img onClick={handleClose} src={ModalCancelIcon} alt="" />
          </div>
          <div className="delete-modal-content-parent">
            <div className="dlt-modal-img">
              <img src={ModalDeleteIcon} alt="" />
            </div>
            <div className="dlte-modal-txt">
              <h3>You are about to delete this product</h3>
            </div>
            <div className="delete-modal-btn-parent">
              <button className="GoBack" onClick={handleClose}>
                Go Back
              </button>
              <button className="dlt-any" onClick={handleDeleteAnyway}>
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Product;
