import React from "react";
import ServiceSecOneImg from "../assets/imgs/service-img-one.png";
import ServiceSecTwoImg from "../assets/imgs/service-img-two.png";
import ServiceSecThreeImg from "../assets/imgs/service-img-three.png";
import ListArrow from "../assets/imgs/serv-list-arrow.png";

function ServicesSecOne() {
  return (
    <>
      <section className="service-sec-one">
        <div className="container-custom">
          <div className="row-parent-serv-sec-one">
            <div className="row justify-content-between row-parent-serv-gap-tab">
              <div className="col-lg-3">
                <div className="content-serv-first-row">
                  <div className="img-serv-one">
                    <img src={ServiceSecOneImg} alt="" />
                  </div>
                  <div className="services-point">
                    <ul>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Risk Management
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Development of project specific documentation
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Incident & Investigations and reporting (Root Cause
                        Analysis)
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        General workplace Inductions and project specific
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="content-serv-first-row">
                  <div className="img-serv-one">
                    <img src={ServiceSecTwoImg} alt="" />
                  </div>
                  <div className="services-point">
                    <ul>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Emergency response preparedness and management
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Toolbox Talks & Presentations
                      </li>
                      <li>
                        ISO 45001, 14001 and 9001 consultancy for certification
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Development of policies, procedures, standards,
                        guidelines, and management plans
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>
                        Development of risk registers and review
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="content-serv-first-row">
                  <div className="img-serv-one">
                    <img src={ServiceSecThreeImg} alt="" />
                  </div>
                  <div className="services-point">
                    <ul>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Corrective action registers and improvement plans
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Site inspections and auditing
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Safety consultancy
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        Safety training and facilitation
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>{" "}
                        HSEQ training needs analysis and matrices
                      </li>
                      <li>
                        {" "}
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>
                        Training/Mentoring/Coaching
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesSecOne;
