import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import SectionOne from "../../components/SectionOne";
import SectionTwo from "../../components/SectionTwo";
import SectionThree from "../../components/SectionThree";
// import SectionFour from "../../components/SectionFour";
import Footer from "../../components/Footer";
import OurValues from "../../components/OurValues";
function Home() {
  return (
    <>
      <Header />
      <Banner
        text="Compliance
Made Easy"
        para="Our content-rich documents give you the perfect starting point.
Choose from our professionally developed products."
      />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      {/* <SectionFour /> */}
      <OurValues />

      <Footer />
    </>
  );
}

export default Home;
