import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import CartCancelImg from "../assets/imgs/cart-cancel.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../utils/Function";
import axios from "axios";
import { useContext } from "react";
import { CartContext } from "../Cart/Cart";

function CartSection({ product }) {
  
  //states
  const [quantity, setQuantity] = useState(1);
  const { id } = useParams();
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const minValue = 1;
  const maxValue = 50;

  //get product by id
  useEffect(() => {

    const token = localStorage.getItem('token');
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-product-rating-ById/${id}`,

    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setProductData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [id]);

  //import functions from cart 
  const { cartItems, getCartTotal, decrementQuantity, CancelProduct, incrementQuantity } =
    useContext(CartContext);


  return (
    <>
      <section className="cartsection-main">
        <div className="container-custom-cart">
          <div className="content-parent-cart">
            <div className="grid-cart-super-parent">
              <div className="grid-cart-parent">
                <div className="grid-cart-inner">
                  <p>Product</p>
                </div>
                <div className="grid-cart-inner text-center">
                  <p>Quantity</p>
                </div>
                <div className="grid-cart-inner text-center">
                  <p>Price</p>
                </div>
              </div>

              {cartItems?.map((item) => (
                  <div className="grid-cart-parent">
                    <div className="grid-cart-inner">
                      <div className="cart-product-description">
                        <img src={`${baseUrl}/${item.product_image
                          }`} alt="" />
                        <h4 className="cart-resuse-text">
                          {item.product_name}
                        </h4>
                      </div>
                    </div>
                    <div className="grid-cart-inner text-center">
                      <div id="field1">
                        <button
                          type="button"
                          id="add"
                          className="plus add-minus-btns"
                          onClick={() => incrementQuantity(item)}
                        >
                          +
                        </button>
                        <input
                          type="number"
                          value={item.quantity}
                          min={minValue}
                          max={maxValue}
                          className="quantity input-add-prdct"
                          readOnly
                        />
                        <button
                          type="button"
                          id="sub"
                          className="minus add-minus-btns"
                          onClick={() => decrementQuantity(item)}
                        >
                          -
                        </button>
                      </div>
                    </div>
                    <div className="grid-cart-inner cart-sec-price text-center">
                      <h4 className="cart-resuse-text">${item.sale_price} x {item.quantity}</h4>
                    </div>
                    <div className="cart-cancel-icon text-center">
                      <img src={CartCancelImg} alt="" onClick={() => CancelProduct(item)} />
                    </div>
                  </div>
                ))}

              <div className="total-cart-page">
                <h4 className="total-cart-one">Total :</h4>
                <h5 className="total-cart-two">$ {getCartTotal()}</h5>
              </div>
            </div>
            <div className="cart-btn-parent d-flex align-items-center justify-content-between">
              <Link to="/Shop" className="position-relative">
                <FontAwesomeIcon icon={faChevronLeft} /> Continue Shopping
              </Link>
              {getCartTotal() == 0 ? (  <Link to="" className="position-relative" style={{background:"grey"}}>
               Checkout <FontAwesomeIcon icon={faChevronRight} />
             </Link>
            ) : (
               <Link to="/Checkout" className="position-relative">
               Checkout <FontAwesomeIcon icon={faChevronRight} />
             </Link>
            )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CartSection;