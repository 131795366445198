import React from 'react';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
import Terms from '../../components/Terms';
import Footer from '../../components/Footer';

function TermsandConditions() {
  return (
    <>
        <Header />
        <Banner text="Terms & Conditions" class="not null"/>
        <Terms />
        <Footer />
    </>
  )
}

export default TermsandConditions