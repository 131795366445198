import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import InspecImg from "../assets/imgs/tools-inspection.png";
import WorkplaceImg from "../assets/imgs/workplace-performance-measurement-reporting.png";
import CrossImg from "../assets/imgs/cross.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { baseUrl } from "../utils/Function";
import { CartContext } from "../Cart/Cart";
function SectionThree() {
  const [sliderSettingstwo] = useState({
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const [showimg, setShowimg] = useState(false);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleCloseimg = () => setShowimg(false);
  const handleShowimg = (product) => {
    setSelectedProduct(product);
    setShowimg(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    console.log(token, "token check");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    axios
      .get(`${baseUrl}/get-all-rating-products`)

      .then((response) => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });

    console.error("No token available. Handle unauthorized access here.");
    setLoading(false);
  }, []);

  const { addToCart } = useContext(CartContext);

  return (
    <>
      <section className="sec-three-ind">
        <div className="container-custom">
          <div className="sec-three-heading text-center">
            <h2>Featured Products</h2>
          </div>
          <div className="sec-three-row-parent">
            <div className="row">
              <div className="d-lg-flex d-none">
                {[...product.slice(-3)].map((product) => (
                  <div className="col-lg-4" key={product.id}>
                    <div className="img-sec-three-ind-parent">
                      <div className="img-sec-three-ind">
                        <img
                          src={`${baseUrl}/${product.product_image}`}
                          alt=""
                        />
                        <div className="descrip-sec-three-img-parent">
                          <div className="descrip-heading text-center">
                            <h3>{product.product_name}</h3>
                          </div>
                          <div className="star-descrip text-center">
                            {Array.from(
                              {
                                length:
                                  product.average_rating != null
                                    ? product.average_rating
                                    : 1,
                              },
                              (_, index) =>
                                product.average_rating != null ? (
                                  <FontAwesomeIcon key={index} icon={faStar} />
                                ) : (
                                  <p style={{ color: "yellow" }}>
                                    {" "}
                                    no ratings{" "}
                                  </p>
                                )
                            )}
                          </div>
                          <div className="descrip-amount text-center">
                            <h5>{product.sale_price}</h5>
                          </div>

                          <div className="readmore">
                            <Link to={`/SingleProduct/${product.product_id}`}>
                              Read More
                            </Link>
                          </div>
                          <div className="descrip-btn-parent mt-3">
                            <button
                              onClick={() => handleShowimg(product)}
                              className="quick-btn"
                            >
                              Quick View
                            </button>
                            <button
                              className="add-btn"
                              onClick={() => addToCart(product)}
                            >
                              Add To Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <Slider className="d-lg-none" {...sliderSettingstwo}>
                {[...product.slice(-3)].map((product) => (
                  <div className="col-lg-4" key={product.id}>
                    <div className="img-sec-three-ind-parent img-sec-three-ind-parent-mobile-slide">
                      <div className="img-sec-three-ind img-sec-three-ind-mobile-slide">
                        <img
                          src={`${baseUrl}/${product.product_image}`}
                          alt=""
                        />
                        <div className="descrip-sec-three-img-parent">
                          <div className="descrip-heading text-center">
                            <h3>{product.product_name}</h3>
                          </div>
                          <div className="star-descrip text-center">
                            {Array.from(
                              {
                                length:
                                  product.average_rating != null
                                    ? product.average_rating
                                    : 1,
                              },
                              (_, index) =>
                                product.average_rating != null ? (
                                  <FontAwesomeIcon key={index} icon={faStar} />
                                ) : (
                                  <p style={{ color: "yellow" }}>
                                    {" "}
                                    no ratings{" "}
                                  </p>
                                )
                            )}
                          </div>
                          <div className="descrip-amount text-center">
                            <h5>{product.sale_price}</h5>
                          </div>

                          <div className="readmore">
                            <Link to={`/SingleProduct/${product.product_id}`}>
                              Read More
                            </Link>
                          </div>
                          <div className="descrip-btn-parent mt-3">
                            <button
                              onClick={() => handleShowimg(product)}
                              className="quick-btn"
                            >
                              Quick View
                            </button>
                            <button
                              className="add-btn"
                              onClick={() => addToCart(product)}
                            >
                              Add To Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <Modal className="img-modal-web" show={showimg} onHide={handleCloseimg}>
        <div className="img-modal-parent position-relative">
          <div className="img-modal-cancel">
            <img onClick={handleCloseimg} src={CrossImg} alt="" />
          </div>
          {/* <img src={`${baseUrl}/${product?.product_image}`} alt="" /> */}
          {selectedProduct && (
            <div className="modal-img">
              <img src={`${baseUrl}/${selectedProduct.product_image}`} alt="" />
            </div>
          )}{" "}
        </div>
      </Modal>
    </>
  );
}

export default SectionThree;
