import React from "react";
import SideBar from "./components/SideBar";
import ManageReviews from "./components/ManageReviews";

function AdminManageReviews() {
  return (
    <>
      <SideBar />
      <ManageReviews />
    </>
  );
}

export default AdminManageReviews;
