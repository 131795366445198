import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Eyeicn from "../../assets/imgs/eye-icon.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import Hue from "../../assets/imgs/hue.png";
import Pagination from "../../components/Pagi/Pagination";
import { baseUrl } from "../../utils/Function";
import axios from "axios";
import { RatingDisplay } from "./ReviewComponent/Review";

function ManageReviews() {
  const [reviews, setReview] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const getReviews = async () => {
      try {
        const res = await axios.get(`${baseUrl}/get-all-review`);
        setReview(res.data);
        console.log(res.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getReviews();
  }, []);

  // pagination
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const customersToDisplay = reviews?.reverse().slice(startIndex, endIndex);
  const totalPages = Math.ceil(reviews?.reverse().length / itemsPerPage);
  //

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Manage Reviews</h2>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="order-list-heading inner-left-head d-flex align-items-center">
                <img src={BulletedIcon} alt="" />
                <h4>Review List</h4>
              </div>
              <div className="main-table-parent-rsp-master">
                <div className="main-table-parent-rsp">
                  <div className="grid-manage-parent-sup">
                    <div className="grid-manage-parent">
                      <div className="grid-manage-inner">
                        <p>Customer Name</p>
                      </div>
                      <div className="grid-manage-inner">
                        <p>Product Name</p>
                      </div>
                      <div className="grid-manage-inner">
                        <p>Review Title</p>
                      </div>
                      <div className="grid-manage-inner">
                        <p>Rating</p>
                      </div>
                      <div className="grid-manage-inner">
                        <p>Status</p>
                      </div>
                      <div className="grid-manage-inner">
                        <p>Action</p>
                      </div>
                    </div>
                    {customersToDisplay?.map((rev) => (
                      <div className="grid-manage-parent grid-manage-parent-rev">
                        <div className="grid-manage-inner">
                          <p>
                            {rev.customer_name ? rev.customer_name : "Customer"}
                          </p>
                        </div>
                        <div className="grid-manage-inner">
                          <p>{rev.product_name}</p>
                        </div>
                        <div className="grid-manage-inner">
                          <p>{rev.review_title}</p>
                        </div>
                        <div className="grid-manage-inner">
                          <div className="mange-star-parent">
                            <RatingDisplay review_rating={rev.review_rating} />
                          </div>
                        </div>

                        {/* check status of review  */}
                        <div className="grid-manage-inner">
                          {rev.review_status == 1 ? (
                            <p>
                              <img className="green-circ" src={Hue} alt="" />{" "}
                              Approved
                            </p>
                          ) : rev.review_status == 2 ? (
                            <p>
                              <img className="yel-circ" src={Hue} alt="" />{" "}
                              Pending
                            </p>
                          ) : rev.review_status == 3 ? (
                            <p>
                              <img className="red-circ" src={Hue} alt="" />{" "}
                              Decline
                            </p>
                          ) : null}
                        </div>

                        <div className="grid-manage-inner">
                          <Link to={`/ReviewsEdit/${rev.review_id}`}>
                            <img src={Eyeicn} alt="" />
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="main-div10 d-flex justify-content-between">
                <div className="pagi mnge-rev-pagi">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageReviews;
