import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import SectionOne from "../../components/SectionOne";
import ProjectTab from "../../components/ProjectTab";
import Footer from "../../components/Footer";
import OurSpecialization from "../../components/OurSpecialization";
import ExpertisePoint from "../../components/ExpertisePoint";
import OurValues from "../../components/OurValues";

function About() {
  return (
    <>
      <Header />
      <Banner text="About" />
      <SectionOne AboutButton="not null" />
      <OurSpecialization />
      <ExpertisePoint />
      <OurValues />
      <ProjectTab />
      <Footer />
    </>
  );
}

export default About;
