import React, { useState, useEffect } from "react";
import SecOneImg from "../assets/imgs/Sec-One-right.png";
import { Link } from "react-router-dom";

function SectionOne() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const translateYFraction = 0.1;
  return (
    <>
      <section className="sec_one-index">
        <div className="container-custom">
          <div className="sec-one-ind-row-parent">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center justify-content-lg-start justify-content-center">
                <div className="content-parent-sec-one-ind">
                  <div className="ind-sec-one-heading">
                    <h2>About Us</h2>
                  </div>
                  <div className="ind-sec-one-sub-head">
                    <h3>ICG Safety Management Services</h3>
                  </div>
                  <div className="ind-sec-one-para">
                    <p>
                      At ICG Safety Management Services, we bring over 25 years
                      of unwavering expertise to the forefront of critical
                      industries such as renewable energy, construction, civil
                      works, commissioning, construction verification, oil and
                      gas, mining, and consulting. Our commitment to excellence
                      has established us as a trusted consulting firm, dedicated
                      to ensuring safety and efficiency in every project we
                      undertake.
                    </p>
                  </div>
                  <div className="about-sec-button">
                    <Link to="/About">Read More</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 position-relative">
                <div className="sec-one-right-parent">
                  <img src={SecOneImg} alt="" />
                </div>
                <div className="exp-right-ind-one">
                  <h3
                    style={{
                      transform: `translateY(${
                        scrollY * translateYFraction
                      }px)`, // Apply translateY transform based on scroll position
                      transition: "transform 0.3s ease", // Add a smooth transition for the effect
                    }}
                  >
                    25 Years of Experience
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionOne;
