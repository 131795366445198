import React, { useContext, useEffect, useLocation } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import CrossImg from "../assets/imgs/cross.png";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { baseUrl } from "../utils/Function";
import { CartContext } from "../Cart/Cart";
import Pagination from "./Pagi/Pagination";

function ShopSectionOne() {
  //states
  const [showimg, setShowimg] = useState(false);
  const [product, setProduct] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleCloseimg = () => setShowimg(false);
  const [sortingOption, setSortingOption] = useState("default");
  const handleShowimg = (product) => {
    setSelectedProduct(product);
    setShowimg(true);
  };

  // Get all Rating Products ~ Api
  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get(`${baseUrl}/get-all-rating-products`)

      .then((response) => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    console.error("No token available. Handle unauthorized access here.");
    setLoading(false);
  }, []);

  // Add to card function Import form Cart
  const { addToCart } = useContext(CartContext);

  // Create a sorting function
  const sortProducts = (option) => {
    switch (option) {
      case "latest":
        return product
          .filter((product) =>
            product.product_name
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          )
          .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date, latest first

      case "lowToHigh":
        return product
          .filter((product) =>
            product.product_name
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          )
          .sort((a, b) => a.sale_price - b.sale_price); // Sort by sale price, low to high

      case "highToLow":
        return product
          .filter((product) =>
            product.product_name
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          )
          .sort((a, b) => b.sale_price - a.sale_price); // Sort by sale price, high to low

      default:
        return product.filter((product) =>
          product.product_name.toLowerCase().includes(searchInput.toLowerCase())
        );
    }
  };
  const sortedProducts = sortProducts(sortingOption);

  //create pagination
  const itemsPerPage = 9;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const SearcedProducts = sortedProducts.filter((product) =>
    product.product_name.toUpperCase().includes(searchInput.toUpperCase())
  );
  const ordersTodisplay = SearcedProducts.reverse().slice(startIndex, endIndex);
  const totalPages = Math.ceil(SearcedProducts.reverse().length / itemsPerPage);
  //

  return (
    <>
      <section className="shop-section">
        <div className="container-custom">
          <div className="search-row-parent">
            <div className="row align-items-center search-row-gap-tab">
              <div className="col-lg-3">
                <div className="show-result">
                  <p>Showing {product.length} results</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="search-bar">
                  <input
                    type="search"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <button>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="sorting-drp-down">
                  <select onChange={(e) => setSortingOption(e.target.value)}>
                    <option value="default">Default sorting</option>
                    <option value="latest">Sort by latest</option>
                    <option value="lowToHigh">
                      Sort by price: low to high
                    </option>
                    <option value="highToLow">
                      Sort by price: high to low
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="shop-main-row-parent">
            <div className="row">
              {ordersTodisplay.map((product) => (
                <div className="col-lg-4 col-md-6" key={product.id}>
                  <div className="img-sec-three-ind-parent">
                    <div className="img-sec-three-ind">
                      <img src={`${baseUrl}/${product.product_image}`} alt="" />
                      <div className="descrip-sec-three-img-parent">
                        <div className="descrip-heading text-center">
                          <h3>{product.product_name}</h3>
                        </div>
                        <div className="star-descrip text-center">
                          {Array.from(
                            {
                              length:
                                product.average_rating != null
                                  ? product.average_rating
                                  : 1,
                            },
                            (_, index) =>
                              product.average_rating != null ? (
                                <FontAwesomeIcon key={index} icon={faStar} />
                              ) : (
                                <p style={{ color: "yellow" }}> no ratings </p>
                              )
                          )}
                        </div>
                        <div className="descrip-amount text-center">
                          <h5>{product.sale_price}</h5>
                        </div>
                        <div className="readmore">
                          <Link to={`/SingleProduct/${product.product_id}`}>
                            Read More
                          </Link>
                        </div>
                        <div className="descrip-btn-parent mt-3">
                          <button
                            onClick={() => handleShowimg(product)}
                            className="quick-btn"
                          >
                            Quick View
                          </button>
                          <button
                            onClick={() => addToCart(product)}
                            className="add-btn"
                          >
                            Add To Cart
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="tab-descripiton-resp-parent d-lg-none">
                      <h3 className="tab-descripiton-resp">
                        {product.product_name}
                      </h3>
                      <div className="pricing-star-and-add-to-cart-tab-parent">
                        <div className="pricing-star">
                          <h4>{product.sale_price}</h4>
                          <div className="resp-star-array star-descrip">
                            {Array.from(
                              {
                                length:
                                  product.average_rating != null
                                    ? product.average_rating
                                    : 1,
                              },
                              (_, index) =>
                                product.average_rating != null ? (
                                  <FontAwesomeIcon key={index} icon={faStar} />
                                ) : (
                                  <p style={{ color: "yellow" }}>
                                    {" "}
                                    no ratings{" "}
                                  </p>
                                )
                            )}
                          </div>
                        </div>
                        <div className="add-to-cart-tab-btn">
                          <Link
                            to={`/SingleProduct/${product.product_id}`}
                            className="add-btn"
                          >
                            Buy Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* pagi */}
          <div className="pagi-div-shop text-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </section>

      <Modal className="img-modal-web" show={showimg} onHide={handleCloseimg}>
        <div className="img-modal-parent position-relative">
          <div className="img-modal-cancel">
            <img onClick={handleCloseimg} src={CrossImg} alt="" />
          </div>
          {selectedProduct && (
            <div className="modal-img">
              <img src={`${baseUrl}/${selectedProduct.product_image}`} alt="" />
            </div>
          )}{" "}
        </div>
      </Modal>
    </>
  );
}

export default ShopSectionOne;
