import React, { useState } from "react";
import FilterIcon from "../../assets/imgs/filt.png";

function CustomerFilter(props) {
  const [totalFilter, setTotalFilter] = useState("");
  const [orderIDFilter, setOrderIDFilter] = useState();
  const [customerNameFilter, setCustomerNameFilter] = useState();
  const [customerCityFilter, setCustomerCityFilter] = useState();
  const [totalAmountFilter, setTotalAmountFilter] = useState();
  const [dateFromFilter, setdateFromFilter] = useState();
  const [dateToFilter, setdateToFilter] = useState();

  const handleFilter = (props) => {
    // console.log(customerNameFilter, "Name")
    // console.log(customerCityFilter, "City")
    // console.log(dateFromFilter, "dateFrom")
    // console.log(dateToFilter, "dateTo")
    setCustomerNameFilter("");
    setCustomerCityFilter("");
    setTotalAmountFilter("");
    setdateFromFilter("");
    setdateToFilter("");
    setOrderIDFilter("");
    setTotalFilter("");
    props.onFilter(
      orderIDFilter,
      customerNameFilter,
      customerCityFilter,
      totalAmountFilter,
      dateFromFilter,
      dateToFilter
    );
    console.log(totalAmountFilter, "totalAmountFilter");
  };
  const handleReset = () => {
    setCustomerNameFilter("");
    setCustomerCityFilter("");
    setTotalAmountFilter("");
    setdateFromFilter("");
    setdateToFilter("");
    handleFilter("");
  };

  return (
    <>
      <div className="col-lg-4 ">
        <div
          className={`filter-box-parent ${
            props.isfilteractive ? "" : props.className
          }`}
        >
          <div className="filter-box-heading">
            <h3>
              <img src={FilterIcon} alt="" />
              Filter
            </h3>
          </div>
          <div className="filter-box-body">
            {props.RemoveOrderId ? (
              ""
            ) : (
              <div className="filter-lb-inp-parent">
                <label htmlFor="filtone">Order ID</label>
                <input
                  type="text"
                  id="filtone"
                  placeholder="Order ID"
                  value={orderIDFilter}
                  onChange={(e) => setOrderIDFilter(e.target.value)}
                />
              </div>
            )}
            <div className="filter-lb-inp-parent">
              <label htmlFor="filttwo">Customer</label>
              <input
                type="text"
                id="filttwo"
                placeholder="Customer"
                value={customerNameFilter}
                onChange={(e) => setCustomerNameFilter(e.target.value)}
                required
              />
            </div>
            {props.city ? (
              ""
            ) : (
              <div className="filter-lb-inp-parent">
                <label htmlFor="filtthree">City</label>
                <input
                  type="text"
                  id="filtthree"
                  placeholder="Sydney"
                  value={customerCityFilter}
                  onChange={(e) => setCustomerCityFilter(e.target.value)}
                />
              </div>
            )}
            {props.RemoveTotal ? (
              ""
            ) : (
              <div className="filter-lb-inp-parent">
                <label htmlFor="filtfour">Total</label>
                <input
                  type="text"
                  id="filtfour"
                  placeholder="Total"
                  value={totalAmountFilter}
                  onChange={(e) => setTotalAmountFilter(e.target.value)}
                />
              </div>
            )}
            {props.RemoveTotal ? (
              ""
            ) : (
              <div className="filter-lb-inp-parent">
                <label htmlFor="filtfive">Date From</label>
                <input
                  className="date-inpt"
                  type="date"
                  id="filtfive"
                  value={dateFromFilter}
                  onChange={(e) => setdateFromFilter(e.target.value)}
                />
              </div>
            )}
            {props.RemoveTotal ? (
              ""
            ) : (
              <div className="filter-lb-inp-parent">
                <label htmlFor="filtsix">Date To</label>
                <input
                  className="date-inpt"
                  type="date"
                  id="filtsix"
                  value={dateToFilter}
                  onChange={(e) => setdateToFilter(e.target.value)}
                />
              </div>
            )}
            <div className="reset-filt-parent">
              <button onClick={handleReset}>
                <img src={FilterIcon} alt="" />
                Reset
              </button>{" "}
              <button onClick={handleFilter}>
                <img src={FilterIcon} alt="" />
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerFilter;
