import React, { useState } from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../assets/imgs/logo-footer.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { baseUrl } from "../utils/Function";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function Footer() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [lastName, setLastName] = useState();
  const [message, setMessage] = useState();

  const MySwal = withReactContent(Swal);
  const handleSuccess = () => {
    MySwal.fire({
      icon: "success",
      title: "Details send",
      time: 4000,
    });
  };
  const handleFailure = () => {
    MySwal.fire({
      icon: "error",
      title: "Something went wrong",
      time: 4000,
    });
  };

  function handleSend(e) {
    e.preventDefault();

    let data = JSON.stringify({
      email: email,
      firstName: name,
      lastName: lastName,
      message: message,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/contact`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        handleSuccess();
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        handleFailure();
        console.log(error);
      });
  }

  return (
    <>
      <footer>
        <div className="container-custom">
          <div className="row-parent-footer position-relative">
            <div className="row row-gap-lg-0 row-gap-md-5">
              <div className="col-lg-3 col-md-12 text-lg-start text-md-center">
                <div className="logo-fo">
                  <Link to="/">
                    <img src={FooterLogo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="fo-mid-sup-parent">
                  <div className="fo-mid-parent">
                    <div className="fo-heading">
                      <h3>Contact Info</h3>
                    </div>
                    <div className="fo-desc-parent">
                      <div className="fo-desc">
                        <FontAwesomeIcon icon={faLocationDot} />
                        <span>Warnbro 6169 WA</span>
                      </div>
                      <div className="fo-desc">
                        <FontAwesomeIcon icon={faPhone} />
                        <Link to="tel:0499 556 050">0499 556 050</Link>
                      </div>
                      <div className="fo-desc">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <Link to="mailto:eddieg@icgsms.com">
                          eddieg@icgsms.com
                        </Link>
                      </div>
                      <div className="fo-desc">
                        <FontAwesomeIcon icon={faClock} />
                        <span>Mon - Sat 09:00 - 17:00</span>
                      </div>
                    </div>
                  </div>
                  <div className="fo-mid-parent">
                    <div className="fo-heading">
                      <h3>Discover</h3>
                    </div>
                    <div className="fo-desc-parent">
                      <div className="fo-desc">
                        <Link to="/PrivacyPolicy">Privacy Policy</Link>
                      </div>
                      <div className="fo-desc">
                        <Link to="/Terms&Conditions">Terms & Conditions</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 pb-lg-0 pb-md-5">
                <div className="fo-form">
                  <div className="fo-heading">
                    <h3>Message Us</h3>
                  </div>
                  <form action="" onSubmit={handleSend}>
                    <input
                      className="fo-field"
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                    <input
                      className="fo-field"
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                    <input
                      className="fo-field"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <textarea
                      className="fo-textarea"
                      placeholder="Message"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    ></textarea>
                    <input
                      className="submit-btn-fo"
                      type="submit"
                      value="Send"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-right-parent2">
            <div className="copy-right-parent d-flex justify-content-between">
              <p>© Copyright 2023 ICGSMS. | All Rights Reserved. </p>
              <Link to="https://techciaga.com.au/">
                Who Designed This Website?
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
