import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductWeb from "../../components/ProductWeb";
import ProductReview from "../../components/ProductReview";
import RelatedProduct from "../../components/RelatedProduct";
import Footer from "../../components/Footer";

function ProductPage() {
  return (
    <>
      <Header />
      <Banner text="Product" />
      <ProductWeb />
      <Footer />
    </>
  );
}

export default ProductPage;
