import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from 'faker';
import { useState } from "react";
import { useEffect } from "react";
import { baseUrl } from "../../utils/Function";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


function App() {
 
const [graphData, setGraphData] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/get-all-orders-just`,
        headers: { 
          'Cookie': 'jwttoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJhZG1pbiIsImlhdCI6MTY5NTgxMzUzOSwiZXhwIjoxNjk1ODE3MTM5fQ.fK8-sArM9gGejosm2uixBOVBkZGP34QYomY3SUmd4Qw'
        }
      };
      
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      setGraphData(response.data)
    } catch (error) {
      console.error(error);
    }
  };

  // Call the fetchData function within the useEffect
  fetchData();
}, []); 


console.log(graphData);



const monthCounts={};

//months reset to 0 if no value
for(let i =0; i < 12; i++){
  monthCounts[i] = 0;
}

graphData.forEach((item)=>{
  const date = new Date(item.created_at);
  const month = date.getMonth();
  monthCounts[month]++;
})

const monthCountsArray = Object.keys(monthCounts).map((month)=>({
  month:parseInt(month),
  count: monthCounts[month]
}));

//Sort months
monthCountsArray.sort((x,y)=> x.month - y.month);

const graphResult = monthCountsArray.map((entry)=> entry.count)
console.log(graphResult)
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: ["Sales Analytics", "Position", "Monthly"],
    },
  },
};

// {const mufaddal = new Date('2023-03-25T09:03:44.000Z');
// console.log(mufaddal.getMonth()+1, "month");}

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];


const data = {
  labels,
  datasets: [
    { 
      label: "Order",
      data: graphResult,
      backgroundColor: "rgb(244, 201, 16)",
      color: "rgb(255, 255, 255)"
    }
  ],
};

  return <Bar options={options} data={data} />;
}

export default App;