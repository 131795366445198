import React from "react";

function ExpertisePoint() {
  return (
    <>
      <section className="Expertise-Point-sec">
        <div className="container-custom">
          <div className="expertise-point-row-parent">
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <div className="content-parent-expertise-row">
                  <div className="heading-expertise-row">
                    <h2 className="m-0">WHSEQ</h2>
                    <h2 className="m-0 yel-heading-exper-row">Excellence</h2>
                  </div>
                  <div className="para-expertise-row">
                    <p>
                      At ICG Safety Management Services, Workplace Health,
                      Safety, Environment, and Quality (WHSEQ) are at the core
                      of everything we do. We specialize in creating and
                      implementing safe systems of work, guaranteeing that every
                      project adheres to the highest WHSEQ standards.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-parent-expertise-row">
                  <div className="heading-expertise-row">
                    <h2 className="m-0">Client-Centric</h2>
                    <h2 className="m-0 yel-heading-exper-row">Approach</h2>
                  </div>
                  <div className="para-expertise-row">
                    <p>
                      We understand that each project is unique. Our
                      client-centric approach means that we tailor our services
                      to your specific requirements. We work alongside you to
                      ensure your management systems align with best practices,
                      and we provide valuable insights and guidance at every
                      stage.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <div className="content-parent-expertise-row">
                  <div className="heading-expertise-row">
                    <h2 className="m-0">Your</h2>
                    <h2 className="m-0 yel-heading-exper-row">
                      Trusted Partner
                    </h2>
                  </div>
                  <div className="para-expertise-row">
                    <p>
                      ICG Safety Management Services is not just a consulting
                      firm; we are your trusted partner in achieving safety,
                      compliance, and excellence in your projects. Our team's
                      dedication to safety and decades of experience makes us
                      the choice for industries where precision, compliance, and
                      safety is paramount. Let us help you navigate the
                      complexities of your industry, ensuring a safer, more
                      efficient, and more successful future.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-parent-expertise-row">
                  <div className="heading-expertise-row">
                    <h2 className="m-0">Our</h2>
                    <h2 className="m-0 yel-heading-exper-row">
                      Vision-Centric
                    </h2>
                  </div>
                  <div className="para-expertise-row">
                    <p>
                      To provide effective and simplified Health, Safety
                      Environmental and Quality systems control, and safe
                      systems of work processes to the operations of the
                      business and client projects that provides drive towards
                      zero harm to people, assets, and the environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExpertisePoint;
