import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../utils/Function";
import axios from "axios";
import { RatingDisplay } from "../Admin/components/ReviewComponent/Review";
import ProductReview from "./ProductReview";
import RelatedProduct from "./RelatedProduct";
import { useContext } from "react";
import { CartContext } from "../Cart/Cart";

function ProductWeb({ productId }) {
  //states
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState();

  // functions and constants
  const { updateQuantity, getProductById } = useContext(CartContext);
  const { id } = useParams();
  const value = getProductById(productData?.product_id);
  let count = value?.quantity;
  const minValue = 1;
  const maxValue = 50;

  //count function
  useEffect(() => {
    setQuantity(count || 0);
  }, [count]);

  // get product by rating id ~ api
  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-product-rating-ById/${id}`,
    };

    axios
      .request(config)
      .then((response) => {
        setProductData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };
  const decrementQuantity = () => {
    setQuantity(quantity - 1);
  };

  const values = productData?.related_products?.split(",");
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [num3, setNum3] = useState(0);

  // for related products
  useEffect(() => {
    if (values) {
      setNum1(parseInt(values[0]));
      setNum2(parseInt(values[1]));
      setNum3(parseInt(values[2]));
    }
  }, [values]);

  const ScrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <section className="product-web-sec">
        <div className="container-custom">
          <div className="row-parent-product-web">
            <div className="row">
              <div className="col-lg-5">
                <div className="product-web-left-img">
                  <img src={`${baseUrl}/${productData.product_image}`} alt="" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="product-web-main-right-parent">
                  <div className="product-web-main-right">
                    <div className="product-web-heading-main">
                      <h2>{productData.product_name}</h2>
                    </div>
                    <div class="rating">
                      <RatingDisplay
                        review_rating={productData.average_rating}
                      />
                    </div>
                    <div className="single-product-price">
                      <h4>${productData.sale_price} </h4>
                    </div>
                    <div className="single-product-top-description">
                      <h4>Description</h4>
                      <p>{productData.product_description}</p>
                    </div>
                  </div>
                  <div className="product-web-main-right product-web-main-right-btm">
                    <div className="quantity-parent-prdct brdr-left-prdct">
                      <div className="quantity-heading-prdt">
                        <h5>Quantity</h5>
                      </div>
                      <div id="field1">
                        <button
                          type="button"
                          id="add"
                          className="plus add-minus-btns"
                          onClick={incrementQuantity}
                        >
                          +
                        </button>
                        <input
                          type="number"
                          value={quantity ? quantity : 0}
                          min={minValue}
                          max={maxValue}
                          className="quantity input-add-prdct"
                          readOnly
                          style={{ color: "black" }}
                        />
                        {quantity > 0 && (
                          <button
                            type="button"
                            id="sub"
                            className="minus add-minus-btns"
                            onClick={decrementQuantity}
                          >
                            -
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="product-web-top-sec-btn">
                      {quantity >= 1 ? (
                        <button
                          onClick={() => {
                            updateQuantity(productData, quantity);
                            ScrollToTop();
                          }}
                        >
                          Add to Cart
                        </button>
                      ) : (
                        <button disabled style={{ background: "grey" }}>
                          Add to Cart
                        </button>
                      )}
                      <Link to="/Cart">Proceed To Checkout</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProductReview productId={id} />
      <RelatedProduct num1={num1} num2={num2} num3={num3} />
    </>
  );
}

export default ProductWeb;
