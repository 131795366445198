import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const isAuthenticated = () => {
  console.log('in authenticated');
 
  const accessToken = localStorage.getItem('token');

  if (accessToken) {

    const decodedToken = jwt_decode(accessToken);
    //console.log(decodedToken)
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

    // if the token has expired
    if (decodedToken.exp && decodedToken.exp < currentTime) {
      // Token has expired
      return false;
    }

    // Token is valid
    return true;
  }

  return false;
};

export const ProtectedRoute = () => {
  console.log('Hello');
  console.log(isAuthenticated());
  const isAuthenticatedUser = isAuthenticated(); 

  return isAuthenticatedUser ? <Outlet/> : <Navigate to="/Login" />;
};
