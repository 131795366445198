import React, { useContext } from "react";
import Bannervid from "../assets/vid/Banner.mp4";
import LogoBanner from "../assets/imgs/logo-banner.png";
import CallIcon from "../assets/imgs/call-icon.png";
import CartIcon from "../assets/imgs/cart-icon.png";
import BannerBtm from "../assets/imgs/banner-btm-img.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CartContext } from "../Cart/Cart";

function Banner(props) {
  const location = useLocation();
  const { cartItems, getCartTotal, decrementQuantity, incrementQuantity } =
    useContext(CartContext);
  const navigate = useNavigate();
  const handleClickToHome = () => {
    navigate("/");
    localStorage.removeItem("cartItems");
    window.location.reload();
  };

  return (
    <>
      <section className="Banner-main">
        <div className="container-custom">
          <div className="content-parent-banner">
            <div className="parent-top-logo">
              <div className="logo-banner-rev d-flex align-items-center justify-content-lg-start justify-content-center">
                <Link to="/">
                  <img src={LogoBanner} alt="" />
                </Link>
              </div>
              <div className="right-hang-banner-parent">
                <div className="right-hang-banner-content">
                  <div className="right-hang-circ-parent">
                    {props.cartIcon != null ? (
                      <></>
                    ) : (
                      <div className="right-hang-circ">
                        <Link className="position-relative" to="/cart">
                          <span>
                            <img src={CartIcon} alt="" />
                          </span>
                          {cartItems.length > 0 && (
                            <span className="badge">{cartItems.length}</span>
                          )}
                        </Link>
                        {/* <i className="fas fa-shopping-cart"></i>
                         {cartCount > 0 && <span className="badge">{cartCount}</span>} */}
                      </div>

                      //     <div className="right-hang-circ">
                      //   <Link to="/cart">
                      //     <span>
                      //       <img src={CartIcon} alt="" />
                      //       {cartCount > 0 && <span className="badge">{cartCount}</span>}
                      //     </span>
                      //   </Link>
                      // </div>
                    )}

                    <div className="right-hang-circ">
                      <Link>
                        <span>
                          <img src={CallIcon} alt="" />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="free-call-hang-right">
                    <h4 className="mb-1">Free Call</h4>
                    <Link to="tel:0499 556 050" className="fw-400-reg-poppins">
                      0499 556 050
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                props.class != null
                  ? "banner-txt-parent text-center privacy-banner"
                  : "banner-txt-parent text-center" | (props.classthank != null)
                  ? "banner-txt-parent text-center thank-banner"
                  : "banner-txt-parent text-center"
              }
            >
              <div
                className={`banner-heading ${
                  location.pathname != "/" && location.pathname != "/ThankYou"
                    ? "middle"
                    : ""
                }`}
              >
                <h2>{props.text}</h2>
              </div>
              {props.para != null ? (
                <div className="banner-para">
                  <p>{props.para}</p>
                </div>
              ) : (
                <></>
              )}
              {props.button != null ? (
                <div className="checkout-page-btn thnkyou-btn">
                  <Link onClick={handleClickToHome}>{props.button}</Link>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {props.span != null ? (
          <></>
        ) : (
          <span className="bannerbtm-img">
            <img src={BannerBtm} alt="" />
          </span>
        )}

        <video
          src={Bannervid}
          loop
          muted
          autoPlay
          playsInline
          className="background-video"
        ></video>
      </section>
    </>
  );
}

export default Banner;
