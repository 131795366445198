import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import SpeciaOne from "../assets/imgs/specia-one.png";
import SpeciaTwo from "../assets/imgs/specia-two.png";
import SpeciaThree from "../assets/imgs/specia-three.png";
import SpeciaFour from "../assets/imgs/specia-four.png";
import SpeciaFive from "../assets/imgs/specia-five.png";
import SpeciaSix from "../assets/imgs/specia-six.png";
import SpeciaSeven from "../assets/imgs/specia-seven.png";
import SpeciaEight from "../assets/imgs/specia-eight.png";
import SpeciaNine from "../assets/imgs/specia-nine.png";

function OurSpecialization() {
  const [sliderSettingstwo] = useState({
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  return (
    <>
      <section className="our-specializaton">
        <div className="container-custom">
          <div className="our-specializaton-content-parent">
            <div className="d-flex align-items-end">
              <div className="parent-of-heading-our-speci">
                <div className="ind-sec-one-heading">
                  <h2>Our</h2>
                </div>
                <div className="our-speci-sub-head">
                  <h2>Specializations</h2>
                </div>
              </div>
              <div className="parent-of-heading-our-speci w-100">
                <hr />
              </div>
            </div>
            <div className="row-parent-specia d-lg-block d-md-block d-none">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="row-content-parent-specia">
                    <div className="ind-sec-one-sub-head">
                      <h4>Renewable Energy</h4>
                      <hr />
                    </div>
                    <div className="ind-sec-one-para">
                      <p>
                        We are passionate about driving the transition to a
                        sustainable future. Our in-depth knowledge of renewable
                        energy projects allows us to guide clients through the
                        intricacies of this ever-evolving industry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="specia-img">
                    <img src={SpeciaOne} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-parent-specia d-lg-block d-md-block d-none">
              <div className="row flex-row-reverse align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="row-content-parent-specia">
                    <div className="ind-sec-one-sub-head">
                      <h4>Construction</h4>
                      <hr />
                    </div>
                    <div className="ind-sec-one-para">
                      <p>
                        With a wealth of experience in construction, we offer
                        comprehensive solutions that prioritize safety, quality,
                        and compliance. From project inception to completion,
                        we're your trusted partner.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="specia-img">
                    <img src={SpeciaTwo} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-parent-specia d-lg-block d-md-block d-none">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="row-content-parent-specia">
                    <div className="ind-sec-one-sub-head">
                      <h4>Civil Works</h4>
                      <hr />
                    </div>
                    <div className="ind-sec-one-para">
                      <p>
                        Our expertise in civil works encompasses a broad
                        spectrum of infrastructure projects. We provide
                        specialized safety and quality oversight to guarantee
                        project success.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="specia-img">
                    <img src={SpeciaThree} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-parent-specia d-lg-block d-md-block d-none">
              <div className="row flex-row-reverse align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="row-content-parent-specia">
                    <div className="ind-sec-one-sub-head">
                      <h4>Commissioning</h4>
                      <hr />
                    </div>
                    <div className="ind-sec-one-para">
                      <p>
                        Precise commissioning is the key to operational
                        excellence. We ensure that systems are thoroughly
                        tested, validated, and ready for seamless operation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="specia-img">
                    <img src={SpeciaFour} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-parent-specia d-lg-block d-md-block d-none">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="row-content-parent-specia">
                    <div className="ind-sec-one-sub-head">
                      <h4>Construction Verification</h4>
                      <hr />
                    </div>
                    <div className="ind-sec-one-para">
                      <p>
                        Quality control is paramount. We conduct meticulous
                        construction verification to certify that projects align
                        with the highest standards.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="specia-img">
                    <img src={SpeciaFive} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-parent-specia d-lg-block d-md-block d-none">
              <div className="row flex-row-reverse align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="row-content-parent-specia">
                    <div className="ind-sec-one-sub-head">
                      <h4>Oil and Gas</h4>
                      <hr />
                    </div>
                    <div className="ind-sec-one-para">
                      <p>
                        Safety in the oil and gas industry is non-negotiable. We
                        specialize in developing and implementing robust safety
                        systems that protect personnel and assets.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="specia-img">
                    <img src={SpeciaSix} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-parent-specia d-lg-block d-md-block d-none">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="row-content-parent-specia">
                    <div className="ind-sec-one-sub-head">
                      <h4>Mining</h4>
                      <hr />
                    </div>
                    <div className="ind-sec-one-para">
                      <p>
                        In the mining sector, safety is paramount. Our mining
                        experts offer guidance and support to enhance safety and
                        productivity in mining operations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="specia-img">
                    <img src={SpeciaSeven} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-parent-specia d-lg-block d-md-block d-none">
              <div className="row flex-row-reverse align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="row-content-parent-specia">
                    <div className="ind-sec-one-sub-head">
                      <h4>Consulting</h4>
                      <hr />
                    </div>
                    <div className="ind-sec-one-para">
                      <p>
                        Our consultancy services cover a broad spectrum,
                        offering tailored solutions to meet your unique needs,
                        whether it's risk assessment, compliance, or process
                        optimization.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="specia-img">
                    <img src={SpeciaEight} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-parent-specia d-lg-block d-md-block d-none">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="row-content-parent-specia">
                    <div className="ind-sec-one-sub-head">
                      <h4>Secondment for Contractor Projects</h4>
                      <hr />
                    </div>
                    <div className="ind-sec-one-para">
                      <p>
                        We provide skilled professionals to augment your project
                        teams. Our secondment services ensure you have the right
                        expertise when and where you need it.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="specia-img">
                    <img src={SpeciaNine} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <Slider className="d-lg-none d-md-none" {...sliderSettingstwo}>
              <div className="row-parent-specia">
                <div className="row align-items-center justify-content-between">
                  <div className="col-lg-5">
                    <div className="row-content-parent-specia">
                      <div className="ind-sec-one-sub-head">
                        <h4>Renewable Energy</h4>
                        <hr />
                      </div>
                      <div className="ind-sec-one-para">
                        <p>
                          We are passionate about driving the transition to a
                          sustainable future. Our in-depth knowledge of
                          renewable energy projects allows us to guide clients
                          through the intricacies of this ever-evolving
                          industry.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="specia-img">
                      <img src={SpeciaOne} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-parent-specia">
                <div className="row flex-row-reverse align-items-center justify-content-between">
                  <div className="col-lg-5">
                    <div className="row-content-parent-specia">
                      <div className="ind-sec-one-sub-head">
                        <h4>Construction</h4>
                        <hr />
                      </div>
                      <div className="ind-sec-one-para">
                        <p>
                          With a wealth of experience in construction, we offer
                          comprehensive solutions that prioritize safety,
                          quality, and compliance. From project inception to
                          completion, we're your trusted partner.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="specia-img">
                      <img src={SpeciaTwo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-parent-specia">
                <div className="row align-items-center justify-content-between">
                  <div className="col-lg-5">
                    <div className="row-content-parent-specia">
                      <div className="ind-sec-one-sub-head">
                        <h4>Civil Works</h4>
                        <hr />
                      </div>
                      <div className="ind-sec-one-para">
                        <p>
                          Our expertise in civil works encompasses a broad
                          spectrum of infrastructure projects. We provide
                          specialized safety and quality oversight to guarantee
                          project success.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="specia-img">
                      <img src={SpeciaThree} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-parent-specia">
                <div className="row flex-row-reverse align-items-center justify-content-between">
                  <div className="col-lg-5">
                    <div className="row-content-parent-specia">
                      <div className="ind-sec-one-sub-head">
                        <h4>Commissioning</h4>
                        <hr />
                      </div>
                      <div className="ind-sec-one-para">
                        <p>
                          Precise commissioning is the key to operational
                          excellence. We ensure that systems are thoroughly
                          tested, validated, and ready for seamless operation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="specia-img">
                      <img src={SpeciaFour} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-parent-specia">
                <div className="row align-items-center justify-content-between">
                  <div className="col-lg-5">
                    <div className="row-content-parent-specia">
                      <div className="ind-sec-one-sub-head">
                        <h4>Construction Verification</h4>
                        <hr />
                      </div>
                      <div className="ind-sec-one-para">
                        <p>
                          Quality control is paramount. We conduct meticulous
                          construction verification to certify that projects
                          align with the highest standards.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="specia-img">
                      <img src={SpeciaFive} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-parent-specia">
                <div className="row flex-row-reverse align-items-center justify-content-between">
                  <div className="col-lg-5">
                    <div className="row-content-parent-specia">
                      <div className="ind-sec-one-sub-head">
                        <h4>Oil and Gas</h4>
                        <hr />
                      </div>
                      <div className="ind-sec-one-para">
                        <p>
                          Safety in the oil and gas industry is non-negotiable.
                          We specialize in developing and implementing robust
                          safety systems that protect personnel and assets.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="specia-img">
                      <img src={SpeciaSix} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-parent-specia">
                <div className="row align-items-center justify-content-between">
                  <div className="col-lg-5">
                    <div className="row-content-parent-specia">
                      <div className="ind-sec-one-sub-head">
                        <h4>Mining</h4>
                        <hr />
                      </div>
                      <div className="ind-sec-one-para">
                        <p>
                          In the mining sector, safety is paramount. Our mining
                          experts offer guidance and support to enhance safety
                          and productivity in mining operations.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="specia-img">
                      <img src={SpeciaSeven} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-parent-specia">
                <div className="row flex-row-reverse align-items-center justify-content-between">
                  <div className="col-lg-5">
                    <div className="row-content-parent-specia">
                      <div className="ind-sec-one-sub-head">
                        <h4>Consulting</h4>
                        <hr />
                      </div>
                      <div className="ind-sec-one-para">
                        <p>
                          Our consultancy services cover a broad spectrum,
                          offering tailored solutions to meet your unique needs,
                          whether it's risk assessment, compliance, or process
                          optimization.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="specia-img">
                      <img src={SpeciaEight} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-parent-specia">
                <div className="row align-items-center justify-content-between">
                  <div className="col-lg-5">
                    <div className="row-content-parent-specia">
                      <div className="ind-sec-one-sub-head">
                        <h4>Secondment for Contractor Projects</h4>
                        <hr />
                      </div>
                      <div className="ind-sec-one-para">
                        <p>
                          We provide skilled professionals to augment your
                          project teams. Our secondment services ensure you have
                          the right expertise when and where you need it.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="specia-img">
                      <img src={SpeciaNine} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurSpecialization;
