// import logo from './logo.svg';
import './custom.css';
import StripeCheckout from 'react-stripe-checkout';
import React, { useState } from 'react';
import axios from 'axios';
// import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { baseUrl } from '../../utils/Function';
import Swal from 'sweetalert2';
import { Navigate, useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

function Stripe(props) {
  const navigate = useNavigate()

    console.log(props.totalAmount, "paisaaa")
  const publishableKey =
  'pk_live_51LINx5QNLednmvH3ptnaQUrsLHnNw3D1cwZk6C2PaEoOrKB8TnBatFAUfWB0h6cC72bhKZioIvyqUkf57WkdATVz00pMcCIXpQ';
  const [product, setProduct] = useState({
    name: '',
    price: null,
  });
  const priceForStripe = product.price * 100;

  const handleSuccess = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Payment was successful',
      time: 4000,
    });
  };
  const handleFailure = () => {
    MySwal.fire({
      icon: 'error',
      title: 'Payment was not successful',
      time: 4000,
    });
  };



  const checkoutAPI = () => {
    // const fullNameValue = props.formData.customer_name
    // if (!isFullNameValid(fullNameValue)) {
    //   props.setError("Please enter a valid full name with only letters and spaces.");
    //   return; // Don't submit the form if the name is invalid
    // }
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/checkoutForm`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(props.formData),
    };

    axios
      .request(config)
      .then((response) => {
        handleSuccess()
        navigate("/ThankYou")
        // MySwal.fire({
        //   icon: 'success',
        //   title: 'Now you can Pay your Amount',
        //   time: 4000,
        // });
      })
      .catch((error) => {
        MySwal.fire({
          icon: 'error',
          title: 'Something went wrong',
          time: 4000,
        });
      });
  }

  const payNow = token => {
    props.handlePaymentPending()
    const data = JSON.stringify({
      amount: props.totalAmount,
      payment: token.id,
    });
    console.log(token, "tokennnnn")
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/payment`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios
      .request(config)
      .then((response) => {
        checkoutAPI()
        props.handleDownloadAll()
        localStorage.removeItem("cartItems");
        props.handlePaymentDone()
        console.log(JSON.stringify(response.data, "----------responseeeeeeeee--------"));
      })
      .catch((error) => {
        handleFailure()
        props.handlePaymentDone()
        console.log(error, "------------------error messageeeeee");
      });

  };



  const customStyles = {
    base: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '36px',
      color: '#32325d',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
    },
  };

  return (
    <div className="container">
      <StripeCheckout
        stripeKey={publishableKey}
        label="Pay Now"
        name="Pay With Credit Card"
        billingAddress
        shippingAddress
        amount={priceForStripe}
        description={`Your total is $${props.totalAmount}`}
        token={payNow}
        style={customStyles} // Apply custom styles here
        className="custom-payment-button"
      />
    </div>
  );
}

export default Stripe;
