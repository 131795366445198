import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Eyeicn from "../../assets/imgs/eye-icon.png";
import FilterIcon from "../../assets/imgs/filt.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import Pagi from "../../assets/imgs/pagi.png";
import Pagination from "../../components/Pagi/Pagination";
import { baseUrl } from "../../utils/Function";
import axios from "axios";
import CustomerFilter from "./CustomerFilter";

function Sales() {
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [customerName, setCustomerName] = useState(null);
  const [ordersCopy, setOrdersCopy] = useState([]);
  const [total, setTotal] = useState(null);

  const applyFilters = (orderId, name, city, total, dateFrom, dateTo) => {
    console.log("Function clicked", total);
    let filteredCustomers = [...ordersCopy]; // Make a copy of the original data

    if (name) {
      filteredCustomers = filteredCustomers.filter((order) =>
        order.customer_name.toLowerCase().includes(name.toLowerCase())
      );
    }
    // if (city) {
    //   filteredCustomers = filteredCustomers.filter(
    //     (order) => order.city.toLowerCase().includes(city.toLowerCase())
    //   );
    // }
    if (orderId) {
      filteredCustomers = filteredCustomers.filter(
        (order) => order.order_id == orderId
      );
    }

    // date filter
    if (dateFrom && dateTo) {
      // Convert dateFrom and dateTo to Date objects (assuming they are in the format yyyy-mm-dd)
      const fromDate = new Date(dateFrom);
      const toDate = new Date(dateTo);

      // Filter the data within the date range
      filteredCustomers = filteredCustomers.filter((order) => {
        const orderDate = new Date(order.created_at);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    } else if (dateFrom) {
      // Convert dateFrom to a Date object
      const fromDate = new Date(dateFrom);

      // Filter the data starting from dateFrom
      filteredCustomers = filteredCustomers.filter((order) => {
        const orderDate = new Date(order.created_at);
        return orderDate >= fromDate;
      });
    } else if (dateTo) {
      // Convert dateTo to a Date object
      const toDate = new Date(dateTo);

      // Filter the data up to dateTo
      filteredCustomers = filteredCustomers.filter((order) => {
        const orderDate = new Date(order.created_at);
        return orderDate <= toDate;
      });
    }
    if (total) {
      console.log("here");
      filteredCustomers = filteredCustomers.filter((order) =>
        order.total_amount.includes(total)
      );
      console.log(total, "totaltotaltotal");
    }
    // // Update the state with filtered data
    setOrders(filteredCustomers);
  };

  // pagination
  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const ordersToDisplay = orders.reverse().slice(startIndex, endIndex);
  const totalPages = Math.ceil(orders.reverse().length / itemsPerPage);
  //

  // api ka useEffect
  useEffect(() => {
    // Define your Axios request configuration
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-all-orders`,
      headers: {},
    };

    // Perform the Axios request
    axios
      .request(config)
      .then((response) => {
        setOrders(response.data);
        setOrdersCopy(response.data);
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        // Handle any errors here
        console.log(error);
      });
  }, []);
  //

  const uniqueOrderIds = new Set();

  // Filter out duplicates and retain only unique orders
  const uniqueOrders = ordersToDisplay.filter((order) => {
    if (!uniqueOrderIds.has(order.order_id)) {
      uniqueOrderIds.add(order.order_id);
      return true;
    }
    return false;
  });

  const [isfilteractive, setFilterActive] = useState(false);

  const toggleFilter = () => {
    setFilterActive(!isfilteractive);
  };
  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Sales</h2>
            </div>
            <div
              className={`reset-filt-parent d-lg-none d-block filter-opn-btn text-md-end text-end ${
                isfilteractive ? "" : ""
              }`}
            >
              <button onClick={toggleFilter}>
                <img src={FilterIcon} alt="" />
                Filter
              </button>
            </div>
            <CustomerFilter
              customerName={customerName}
              city="city"
              total={total}
              onFilter={applyFilters}
              reset="none"
              filter="none"
              isfilteractive={isfilteractive}
              className={`filter-unactive d-lg-none d-block${
                isfilteractive ? "filter-unactive" : ""
              }`}
            />
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="order-list-heading inner-left-head d-flex align-items-center">
                <img src={BulletedIcon} alt="" />
                <h4>Order List</h4>
              </div>
              <div className="main-table-parent-rsp-master">
                <div className="main-table-parent-rsp">
                  <div className="row pd-customer-row">
                    <div className="col-lg-8">
                      <div className="grid-sales-parent sales-grid-parent">
                        <div className="inner-sale-grid">
                          <div className="inner-grid-sales">
                            <p>Order ID</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>Customer</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>Email</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>Total</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>Date Added</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>Action</p>
                          </div>
                        </div>

                        {uniqueOrders.map((order) => (
                          <div
                            className="inner-sale-grid grid-sales-two"
                            key={order.order_id}
                          >
                            <div className="inner-grid-sales">
                              <p>#{order.order_id}</p>
                            </div>
                            <div className="inner-grid-sales">
                              <p>{order.customer_name}</p>
                            </div>
                            <div className="inner-grid-sales">
                              <p>{order.email}</p>
                            </div>
                            <div className="inner-grid-sales">
                              <p>${order.total_amount}</p>
                            </div>
                            <div className="inner-grid-sales">
                              <p>{order.created_at}</p>
                            </div>
                            <div className="inner-grid-sales">
                              <p>
                                <Link to={`/SalesView/${order.order_id}`}>
                                  <img src={Eyeicn} alt="" />
                                </Link>
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <CustomerFilter
                      customerName={customerName}
                      city="city"
                      total={total}
                      onFilter={applyFilters}
                      reset="none"
                      filter="none"
                      className="web-cust-filter"
                    />
                  </div>
                </div>
                `{" "}
                {/* <div className="col-lg-4">
                      <div className="filter-box-parent">
                        <div className="filter-box-heading">
                          <h3>
                            <img src={FilterIcon} alt="" />
                            Filter
                          </h3>
                        </div>
                        <div className="filter-box-body">
                          <div className="filter-lb-inp-parent">
                            <label htmlFor="filtone">Order ID</label>
                            <input
                              type="text"
                              id="filtone"
                              placeholder="Order ID"
                            />
                          </div>
                          <div className="filter-lb-inp-parent">
                            <label htmlFor="filttwo">Customer</label>
                            <input
                              type="text"
                              id="filttwo"
                              placeholder="Customer"
                            />`
                          </div>
                          <div className="filter-lb-inp-parent">
                            <label htmlFor="filtthree">City</label>
                            <input
                              type="text"
                              id="filtthree"
                              placeholder="Sydney"
                            />
                          </div>
                          <div className="filter-lb-inp-parent">
                            <label htmlFor="filtfour">Total</label>
                            <input
                              type="text"
                              id="filtfour"
                              placeholder="Total"
                            />
                          </div>
                          <div className="filter-lb-inp-parent">
                            <label htmlFor="filtfive">Date From</label>
                            <input
                              className="date-inpt"
                              type="date"
                              id="filtfive"
                            />
                          </div>
                          <div className="filter-lb-inp-parent">
                            <label htmlFor="filtsix">Date To</label>
                            <input
                              className="date-inpt"
                              type="date"
                              id="filtsix"
                            />
                          </div>
                          <div className="reset-filt-parent">
                            <input type="reset" value="Reset" />
                            <button>Filter</button>
                          </div>
                        </div>
                      </div>
                    </div> */}
              </div>
              <div className="main-div10 d-flex justify-content-between">
                <div className="pagi">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="txt-right-pagi">
                  <p className="">
                    Showing {currentPage} to {totalPages} ({totalPages} ~ Pages)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sales;
