import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFax,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import EnvelopeImg from "../assets/imgs/envelope.png";
import { baseUrl } from "../utils/Function";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

function Contact() {
  //states
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [lastName, setLastName] = useState();
  const [message, setMessage] = useState();
  const MySwal = withReactContent(Swal);

  // to send data
  function handleSend(e) {
    e.preventDefault();

    let data = JSON.stringify({
      email: email,
      firstName: name,
      lastName: lastName,
      message: message,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/contact`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        MySwal.fire({
          icon: "success",
          title: "Message Send Successfully",
          time: 3000,
        });
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: "Something went wrong",
          time: 3000,
        });
        console.log(error);
      });
  }

  return (
    <>
      <section className="contact-section">
        <div className="container-contact-custom">
          <div className="contact-row-parent">
            <div className="row justify-content-between">
              <div className="col-lg-4">
                <div className="contact-heading">
                  <h2>Head Office</h2>
                </div>
                <div className="contact-detail">
                  <ul>
                    <li>
                      <span className="text-center">
                        <FontAwesomeIcon icon={faLocationDot} />
                      </span>
                      Warnbro 6169 WA
                    </li>
                    <li>
                      <span className="text-center">
                        <FontAwesomeIcon icon={faPhone} />
                      </span>
                      <Link
                        className="text-decoration-none"
                        to="tel:0499 556 050"
                      >
                        Mobile : 0499 556 050
                      </Link>
                    </li>
                    {/* <li><span className="text-center"><FontAwesomeIcon icon={faFax} /></span>Fax: PO Box 8443 Warnbro 6169 WA</li> */}
                    <li>
                      <span className="text-center">
                        <img src={EnvelopeImg} alt="" />
                      </span>
                      <Link
                        className="text-decoration-none"
                        to="mailto:eddieg@icgsms.com"
                      >
                        eddieg@icgsms.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="contact-heading">
                  <h2>Get in Touch</h2>
                </div>
                <div className="contact-input-row-parent">
                  <form onSubmit={handleSend} className="contact-form">
                    <div className="row">
                      <div className="col-6">
                        <div className="contact-input-parent">
                          <input
                            type="text"
                            placeholder="John"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6 contact-input-last-name-mob-gap">
                        <div className="contact-input-parent">
                          <input
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="contact-input-parent">
                          <input
                            type="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="contact-input-parent">
                          <textarea
                            placeholder="Message"
                            value={message}
                            onChange={(e) => {
                              setMessage(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="contact-input-submt">
                      <input type="submit" value="Send Message" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
