import React from "react";
import { useState, useEffect } from "react";
import ServSecTwoImg from "../assets/imgs/serv-sec-two-img.png";
import ListArrow from "../assets/imgs/serv-list-arrow.png";

function ServicesSecTwo() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const translateYFraction = 0.03;
  return (
    <>
      <section className="services-sec-two">
        <div className="container-custom">
          <div className="serv-sec-two-row-parent">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-5 position-relative">
                <div className="serv-sec-two-img">
                  <img src={ServSecTwoImg} alt="" />
                </div>
                <div className="exp-right-ind-one end-0">
                  <h3
                    style={{
                      transform: `translateY(${
                        scrollY * translateYFraction
                      }px)`, // Apply translateY transform based on scroll position
                      transition: "transform 0.3s ease", // Add a smooth transition for the effect
                    }}
                  >
                    Training
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="serv-sec-two-content-parent">
                  <div className="serv-sec-two-heading">
                    <h2>Training</h2>
                  </div>
                  <div className="serv-sec-two-list">
                    <ul>
                      <li>
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>
                        Project-Specific Training Policies, Procedures, and
                        Standards
                      </li>
                      <li>
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>
                        Instructional System Design
                      </li>
                      <li>
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>
                        Course Design, Classroom Delivery
                      </li>
                      <li>
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>
                        Curriculum Development
                      </li>
                      <li>
                        <span>
                          <img src={ListArrow} alt="" />
                        </span>
                        Training Assessment Development and Delivery Services{" "}
                        <br />
                        Competency Modelling Risk analysis and workshop
                        facilitation
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesSecTwo;
