import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
// import Modal from 'react-bootstrap/Modal';
import Wapisi from "../../assets/imgs/ret-icn.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// import ModalCancelIcon from "../../assets/imgs/modal-cancel.png";
import axios from "axios";
import { baseUrl } from "../../utils/Function";

function SalesView() {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // const [showtwo, setShowtwo] = useState(false);

  // const handleClosetwo = () => setShowtwo(false);
  // const handleShowtwo = () => setShowtwo(true);

  const [orderData, setOrderData] = useState();
  const [customerData, setCustomerData] = useState();
  // const [myId, setMyId] = useState();

  const { id } = useParams();
  console.log(id, "iddd");
  // api integrate
  useEffect(() => {
    // Define your Axios configuration
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-order-by-id/${id}`,
      headers: {
        Cookie:
          "jwttoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE2LCJpc0FkbWluIjpmYWxzZSwiaWF0IjoxNjk1MzY1OTA5LCJleHAiOjE2OTUzNjk1MDl9.FmIRuGX6kxaejF4OLBNWZR5GuY42ErG0Sd8xW6oZdBc",
      },
    };

    // Make the Axios request within the useEffect
    axios
      .request(config)
      .then((response) => {
        setOrderData(response.data);
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const MyId = orderData ? orderData[0].customer_id : id;

  //   api for customer data
  useEffect(() => {
    // Define your Axios configuration
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-customer-by-id/${MyId}`,
      headers: {
        Cookie:
          "jwttoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE2LCJpc0FkbWluIjpmYWxzZSwiaWF0IjoxNjk1MzY1OTA5LCJleHAiOjE2OTUzNjk1MDl9.FmIRuGX6kxaejF4OLBNWZR5GuY42ErG0Sd8xW6oZdBc",
      },
    };

    // Make the Axios request within the useEffect
    axios
      .request(config)
      .then((response) => {
        setCustomerData(response.data);
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [MyId]);

  console.log(customerData, "customerData");

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Sales</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                <Link to="/Sales">
                  <img src={Wapisi} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="sales-view-content-parent">
                <div className="sal-view-ordheading">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <h4>
                    Order (#{orderData != null ? orderData[0].order_id : ""})
                  </h4>
                </div>
                <div className="inv-row-parent">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="env-box position-relative">
                        <h5>Invoice</h5>
                        <p>{orderData != null ? orderData[0].order_id : ""}</p>
                        {/* <div className="setting-icn-box" onClick={handleShow}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                </div> */}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="env-box">
                        <h5>Customer</h5>

                        <p>
                          {orderData != null ? orderData[0].customer_name : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="env-box">
                        <h5>Date Added</h5>

                        <p>
                          {orderData != null ? orderData[0].created_at : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-table-parent-rsp-master">
                  <div className="main-table-parent-rsp">
                    <div className="sales-view-tble-parent">
                      <div className="sales-view-table">
                        <div className="sales-view-table-inner sale-indent">
                          <p>Product</p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p>Quantity</p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p>Total</p>
                        </div>
                      </div>
                      {orderData?.map((product) => (
                        <div className="sales-view-table sales-view-table-semi">
                          <div className="sales-view-table-inner sale-indent brdr-right-sl-view">
                            <p>{product?.product_name}</p>
                          </div>
                          <div className="sales-view-table-inner text-center brdr-right-sl-view">
                            <p>{product?.product_quantity}</p>
                          </div>
                          <div className="sales-view-table-inner text-center">
                            <p>
                              $
                              {product?.product_price *
                                product?.product_quantity}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="billing-row-parent">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="billing-box position-relative">
                        <h5>Billing Address</h5>
                        <p>
                          {orderData != null ? orderData[0].customer_name : ""}
                        </p>
                        <p>{customerData != null ? customerData?.email : ""}</p>
                        <p>
                          {customerData != null
                            ? customerData?.billing_address
                            : ""}{" "}
                        </p>
                        <p>
                          {customerData != null ? customerData?.country : ""}
                        </p>
                        <p>
                          {customerData != null
                            ? customerData?.postal_code
                            : ""}
                        </p>
                        <p>{customerData != null ? customerData?.city : ""}</p>
                        {/* <div className="setting-icn-box" onClick={handleShowtwo}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="method-payment-row-prnt">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="py-mthd-box">
                        <h5>Payment Method</h5>
                        <p>Credit Card</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal className="DeleteModal" show={show} onHide={handleClose}>
                <div className="billing-modal-parent position-relative">
                    <div className="billing-modal-inner-parent">
                        <div className="modal-close-button text-end"><img onClick={handleClose} src={ModalCancelIcon} alt="" /></div>
                        <div className="billing-heading"><h3>Billing Address</h3></div>
                        <hr />
                        <div className="bill-modl-input-parent">
                            <div className="inner-input-bill-mdl">
                                <label htmlFor="fl">Full Name</label>
                                <input type="text" placeholder="Mark Allen" id="fl" />
                            </div>
                            <div className="inner-input-bill-mdl">
                                <label htmlFor="cnt">Contact #</label>
                                <input type="number" placeholder="1234 567 890" id="cnt" />
                            </div>
                            <div className="inner-input-bill-mdl">
                                <label htmlFor="emladd">Email Address</label>
                                <input type="email" placeholder="mark_allen@gmail.com" id="emladd" />
                            </div>
                            <div className="inner-input-bill-mdl">
                                <label htmlFor="blngadd">Billing Address</label>
                                <input type="text" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ." id="blngadd" />
                            </div>
                            <div className="inner-input-bill-mdl">
                                <label htmlFor="cntry">Country</label>
                                <input type="text" placeholder="Australia" id="cntry" />
                            </div>
                            <div className="inner-input-bill-mdl">
                                <label htmlFor="city">City</label>
                                <input type="text" placeholder="Lorem Ipsum" id="city" />
                            </div>
                            <div className="inner-input-bill-mdl">
                                <label htmlFor="pstcde">Post Code</label>
                                <input type="number" placeholder="11111" id="pstcde" />
                            </div>
                        </div>
                        <div className="save-btn-bill delete-modal-btn-parent justify-content-end">
                            <button onClick={handleClose}>Save</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal className="DeleteModal INV-modal" show={showtwo} onHide={handleClosetwo}>
                <div className="billing-modal-parent position-relative">
                    <div className="billing-modal-inner-parent">
                        <div className="modal-close-button text-end"><img onClick={handleClosetwo} src={ModalCancelIcon} alt="" /></div>
                        <div className="billing-heading text-center"><h3>INV-2022-000</h3></div>
                        <hr />
                        <div className="inv-modal-input-parent">
                            <div className="grid-inpt-modal-parent">
                                <div className="grid-inpt-modal">
                                    <label htmlFor="inv-mdl-one">Order #</label>
                                    <input type="number" placeholder="1446" id="inv-mdl-one" />
                                </div>
                                <div className="grid-inpt-modal">
                                    <label htmlFor="inv-mdl-two">Date Added</label>
                                    <input type="text" placeholder="25/07/2023" id="inv-mdl-two" />
                                </div>
                            </div>
                            <div className="grid-inpt-modal-parent">
                                <div className="grid-inpt-modal">
                                    <label htmlFor="inv-mdl-three">Name</label>
                                    <input type="text" placeholder="Mark Allen" id="inv-mdl-three" />
                                </div>
                                <div className="grid-inpt-modal">
                                    <label htmlFor="inv-mdl-four">Email</label>
                                    <input type="email" placeholder="mark_allen@gmail.com" id="inv-mdl-four" />
                                </div>
                                <div className="grid-inpt-modal">
                                    <label htmlFor="inv-mdl-five">Contact #</label>
                                    <input type="number" placeholder="1234 567 890" id="inv-mdl-five" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="grid-inpt-modal">
                                        <label htmlFor="inv-mdl-six">Billing Address</label>
                                        <textarea id="inv-mdl-six" placeholder="Lorem ipsum dolor sit amet, consect etur adipiscing elit, sed do eiusmod 
tempor incididunt magna aliqua."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="grid-inpt-modal">
                                        <label htmlFor="inv-mdl-seven">Payment Method</label>
                                        <input type="text" id="inv-mdl-six" placeholder="Credit Card" />
                                    </div>
                                </div>
                            </div>
                            <div className="table-modal-parent">
                                <div className="table-modal-inner-parent">
                                    <div className="table-modal-inner text-center mdl-right-border"><p>Product</p></div>
                                    <div className="table-modal-inner text-center mdl-right-border"><p>Quantity</p></div>
                                    <div className="table-modal-inner text-center mdl-right-border"><p>Unit Price</p></div>
                                    <div className="table-modal-inner text-start pd-left-mdl-ttl"><p>Total</p></div>
                                </div>
                                <div className="table-modal-inner-parent mdl-top-border">
                                    <div className="table-modal-inner text-center mdl-right-border"><p>Product Name</p></div>
                                    <div className="table-modal-inner text-center mdl-right-border"><p>2</p></div>
                                    <div className="table-modal-inner text-center mdl-right-border"><p>$122.00</p></div>
                                    <div className="table-modal-inner text-start pd-left-mdl-ttl"><p>$244.00</p></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal> */}
    </>
  );
}

export default SalesView;
