import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import InspecImg from "../assets/imgs/tools-inspection.png";
import WorkplaceImg from "../assets/imgs/workplace-performance-measurement-reporting.png";
import CrossImg from "../assets/imgs/cross.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { baseUrl } from "../utils/Function";
import axios from "axios";
import Slider from "react-slick";
import { RatingDisplay } from "../Admin/components/ReviewComponent/Review";

function RelatedProduct(props) {
  const [showimg1, setShowimg1] = useState(false);
  const [showimg2, setShowimg2] = useState(false);
  const [showimg3, setShowimg3] = useState(false);
  const [dataById, setDataById] = useState("");
  const [dataById2, setDataById2] = useState("");
  const [dataById3, setDataById3] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { num1, num2, num3 } = props;
  const handleCloseimg1 = () => setShowimg1(false);
  const handleCloseimg2 = () => setShowimg2(false);
  const handleCloseimg3 = () => setShowimg3(false);

  const handleShowimg1 = (id) => {
    setShowimg1(true);
  };
  const handleShowimg2 = (id) => {
    setShowimg2(true);
  };
  const handleShowimg3 = (id) => {
    setShowimg3(true);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-productById/${num1}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Make the Axios request
    axios
      .request(config)
      .then((response) => {
        setDataById(response.data);
      })
      .catch((error) => {});
  }, [num1, num2, num3]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-productById/${num2}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Make the Axios request
    axios
      .request(config)
      .then((response) => {
        setDataById2(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [num1, num2, num3]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-productById/${num3}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Make the Axios request
    axios
      .request(config)
      .then((response) => {
        setDataById3(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [num1, num2, num3]);
  const [sliderSettingsproduct] = useState({
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  // if related products
  if (num1 && num2 && num3) {
    return (
      <>
        <section className="related-products-sec">
          <div className="container-custom">
            <div className="related-pr-content-parent">
              <div className="review-sec-heading">
                <h2>
                  <span className="position-relative">Related Products</span>
                </h2>
              </div>
              <div className="sec-three-row-parent">
                <div className="row d-lg-flex d-none">
                  <div className="col-lg-4">
                    <div className="img-sec-three-ind-parent">
                      <div className="img-sec-three-ind">
                        <img
                          src={`${baseUrl}/${dataById.product_image}`}
                          alt=""
                        />
                        <div className="descrip-sec-three-img-parent">
                          <div className="descrip-heading text-center">
                            <h3>{dataById.product_name}</h3>
                          </div>
                          <div className="star-descrip text-center">
                            <RatingDisplay
                              review_rating={dataById.average_rating}
                            />
                          </div>
                          <div className="descrip-amount text-center">
                            <h5>${dataById.sale_price}</h5>
                          </div>
                          <div className="readmore">
                            <Link
                              onClick={() =>
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                })
                              }
                              to={`/SingleProduct/${dataById.product_id}`}
                            >
                              Read More
                            </Link>
                          </div>
                          <div className="descrip-btn-parent mt-3 mt-3">
                            <button
                              onClick={handleShowimg1}
                              className="quick-btn"
                            >
                              Quick View
                            </button>
                            <button className="add-btn">Add To Cart</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="img-sec-three-ind-parent">
                      <div className="img-sec-three-ind">
                        <img
                          src={`${baseUrl}/${dataById2.product_image}`}
                          alt=""
                        />
                        <div className="descrip-sec-three-img-parent">
                          <div className="descrip-heading text-center">
                            <h3>{dataById2.product_name}</h3>
                          </div>
                          <div className="star-descrip text-center">
                            <RatingDisplay
                              review_rating={dataById2.average_rating}
                            />
                          </div>
                          <div className="descrip-amount text-center">
                            <h5>${dataById2.sale_price}</h5>
                          </div>
                          <div className="readmore">
                            <Link
                              to={`/SingleProduct/${dataById2.product_id}`}
                              onClick={() =>
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                })
                              }
                            >
                              Read More
                            </Link>
                          </div>
                          <div className="descrip-btn-parent mt-3 mt-3">
                            <button
                              onClick={() =>
                                handleShowimg2(dataById2.product_id)
                              }
                              className="quick-btn"
                            >
                              Quick View
                            </button>
                            <button className="add-btn">Add To Cart</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="img-sec-three-ind-parent">
                      <div className="img-sec-three-ind">
                        <img
                          src={`${baseUrl}/${dataById3.product_image}`}
                          alt=""
                        />
                        <div className="descrip-sec-three-img-parent">
                          <div className="descrip-heading text-center">
                            <h3>{dataById3.product_name}</h3>
                          </div>
                          <div className="star-descrip text-center">
                            <RatingDisplay
                              review_rating={dataById3.average_rating}
                            />
                          </div>
                          <div className="descrip-amount text-center">
                            <h5>${dataById3.sale_price}</h5>
                          </div>
                          <div className="readmore">
                            <Link
                              to={`/SingleProduct/${dataById3.product_id}`}
                              onClick={() =>
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                })
                              }
                            >
                              Read More
                            </Link>
                          </div>
                          <div className="descrip-btn-parent mt-3 mt-3">
                            <button
                              onClick={handleShowimg3}
                              className="quick-btn"
                            >
                              Quick View
                            </button>
                            <button className="add-btn">Add To Cart</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Slider className="d-lg-none" {...sliderSettingsproduct}>
                  <div className="col-lg-4">
                    <div className="img-sec-three-ind-parent">
                      <div className="img-sec-three-ind related-pr-resp-img">
                        <img
                          src={`${baseUrl}/${dataById.product_image}`}
                          alt=""
                        />
                        <div className="descrip-sec-three-img-parent d-lg-block d-none">
                          <div className="descrip-heading text-center">
                            <h3>{dataById.product_name}</h3>
                          </div>
                          <div className="star-descrip text-center">
                            <RatingDisplay
                              review_rating={dataById.average_rating}
                            />
                          </div>
                          <div className="descrip-amount text-center">
                            <h5>${dataById.sale_price}</h5>
                          </div>
                          <div className="readmore">
                            <Link
                              onClick={() =>
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                })
                              }
                              to={`/SingleProduct/${dataById.product_id}`}
                            >
                              Read More
                            </Link>
                          </div>
                          <div className="descrip-btn-parent mt-3 mt-3">
                            <button
                              onClick={handleShowimg1}
                              className="quick-btn"
                            >
                              Quick View
                            </button>
                            <button className="add-btn">Add To Cart</button>
                          </div>
                        </div>
                        <div className="tab-descripiton-resp-parent d-lg-none">
                          <h3 className="tab-descripiton-resp">
                            {dataById.product_name}
                          </h3>
                          <div className="pricing-star-and-add-to-cart-tab-parent">
                            <div className="pricing-star">
                              <h4>${dataById.sale_price}</h4>
                              <div className="resp-star-array star-descrip">
                                <RatingDisplay
                                  review_rating={dataById.average_rating}
                                />
                              </div>
                            </div>
                            <div className="add-to-cart-tab-btn">
                              <Link to="" className="add-btn">
                                Add to Cart
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="img-sec-three-ind-parent">
                      <div className="img-sec-three-ind related-pr-resp-img">
                        <img
                          src={`${baseUrl}/${dataById2.product_image}`}
                          alt=""
                        />
                        <div className="descrip-sec-three-img-parent d-lg-block d-none">
                          <div className="descrip-heading text-center">
                            <h3>{dataById.product_name}</h3>
                          </div>
                          <div className="star-descrip text-center">
                            <RatingDisplay
                              review_rating={dataById.average_rating}
                            />
                          </div>
                          <div className="descrip-amount text-center">
                            <h5>${dataById.sale_price}</h5>
                          </div>
                          <div className="readmore">
                            <Link
                              onClick={() =>
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                })
                              }
                              to={`/SingleProduct/${dataById.product_id}`}
                            >
                              Read More
                            </Link>
                          </div>
                          <div className="descrip-btn-parent mt-3 mt-3">
                            <button
                              onClick={handleShowimg1}
                              className="quick-btn"
                            >
                              Quick View
                            </button>
                            <button className="add-btn">Add To Cart</button>
                          </div>
                        </div>
                        <div className="tab-descripiton-resp-parent d-lg-none">
                          <h3 className="tab-descripiton-resp">
                            {dataById2.product_name}
                          </h3>
                          <div className="pricing-star-and-add-to-cart-tab-parent">
                            <div className="pricing-star">
                              <h4>${dataById2.sale_price}</h4>
                              <div className="resp-star-array star-descrip">
                                <RatingDisplay
                                  review_rating={dataById2.average_rating}
                                />
                              </div>
                            </div>
                            <div className="add-to-cart-tab-btn">
                              <Link to="" className="add-btn">
                                Add to Cart
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="img-sec-three-ind-parent">
                      <div className="img-sec-three-ind related-pr-resp-img">
                        <img
                          src={`${baseUrl}/${dataById3.product_image}`}
                          alt=""
                        />
                        <div className="descrip-sec-three-img-parent d-lg-block d-none">
                          <div className="descrip-heading text-center">
                            <h3>{dataById.product_name}</h3>
                          </div>
                          <div className="star-descrip text-center">
                            <RatingDisplay
                              review_rating={dataById.average_rating}
                            />
                          </div>
                          <div className="descrip-amount text-center">
                            <h5>${dataById.sale_price}</h5>
                          </div>
                          <div className="readmore">
                            <Link
                              onClick={() =>
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                })
                              }
                              to={`/SingleProduct/${dataById.product_id}`}
                            >
                              Read More
                            </Link>
                          </div>
                          <div className="descrip-btn-parent mt-3 mt-3">
                            <button
                              onClick={handleShowimg1}
                              className="quick-btn"
                            >
                              Quick View
                            </button>
                            <button className="add-btn">Add To Cart</button>
                          </div>
                        </div>
                        <div className="tab-descripiton-resp-parent d-lg-none">
                          <h3 className="tab-descripiton-resp">
                            {dataById3.product_name}
                          </h3>
                          <div className="pricing-star-and-add-to-cart-tab-parent">
                            <div className="pricing-star">
                              <h4>${dataById3.sale_price}</h4>
                              <div className="resp-star-array star-descrip">
                                <RatingDisplay
                                  review_rating={dataById3.average_rating}
                                />
                              </div>
                            </div>
                            <div className="add-to-cart-tab-btn">
                              <Link to="" className="add-btn">
                                Add to Cart
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        <Modal
          className="img-modal-web"
          show={showimg1}
          onHide={handleCloseimg1}
        >
          <div className="img-modal-parent position-relative">
            <div className="img-modal-cancel">
              <img onClick={handleCloseimg1} src={CrossImg} alt="" />
            </div>
            {/* <img src={`${baseUrl}/${product?.product_image}`} alt="" /> */}
            {dataById && (
              <div className="modal-img">
                <img src={`${baseUrl}/${dataById.product_image}`} alt="" />
              </div>
            )}{" "}
          </div>
        </Modal>

        <Modal
          className="img-modal-web"
          show={showimg2}
          onHide={handleCloseimg2}
        >
          <div className="img-modal-parent position-relative">
            <div className="img-modal-cancel">
              <img onClick={handleCloseimg2} src={CrossImg} alt="" />
            </div>
            {/* <img src={`${baseUrl}/${product?.product_image}`} alt="" /> */}
            {dataById2 && (
              <div className="modal-img">
                <img src={`${baseUrl}/${dataById2.product_image}`} alt="" />
              </div>
            )}{" "}
          </div>
        </Modal>

        <Modal
          className="img-modal-web"
          show={showimg3}
          onHide={handleCloseimg3}
        >
          <div className="img-modal-parent position-relative">
            <div className="img-modal-cancel">
              <img onClick={handleCloseimg3} src={CrossImg} alt="" />
            </div>
            {/* <img src={`${baseUrl}/${product?.product_image}`} alt="" /> */}
            {dataById3 && (
              <div className="modal-img">
                <img src={`${baseUrl}/${dataById3.product_image}`} alt="" />
              </div>
            )}{" "}
          </div>
        </Modal>
      </>
    );
  }
  // for no related products
  else {
    <>
      <div className="review-sec-heading">
        <h2>
          <span className="position-relative">Related Products</span>
        </h2>
      </div>
    </>;
  }
}

export default RelatedProduct;
