import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Wapisi from "../../assets/imgs/ret-icn.png";
import Modal from "react-bootstrap/Modal";
import EditGrey from "../../assets/imgs/edit-grey.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ModalTickIcon from "../../assets/imgs/TickMark.png";
import ModalCancelIcon from "../../assets/imgs/modal-cancel.png";
import { baseUrl } from "../../utils/Function";
import axios from "axios";
import Swal from "sweetalert2";
import { RatingDisplay } from "./ReviewComponent/Review";
function EditReviews() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { id } = useParams(); // Access the 'id' from the route parameters
  const [reviewData, setReviewData] = useState(null); // State to hold the fetched review data
  const naviate = useNavigate();
  useEffect(() => {
    // Construct the API URL with the 'id'
    const apiUrl = `${baseUrl}/get-reviewById/${id}`;

    // Make the API request
    axios
      .get(apiUrl)
      .then((response) => {
        setReviewData(response.data);
      })
      .catch((error) => {
        console.error(error);
        // Handle errors here
      });
  }, [id]);

  console.log(reviewData, "reviewDatareviewData");

  const handleDelete = () => {
    const apiUrl = `${baseUrl}/delete-review/${id}`;
    axios
      .delete(apiUrl)
      .then((response) => {
        Swal.fire({
          title: "Delete",
          text: "Review Declined successfully",
          icon: "warning",
          confirmButtonText: "Close",
        });
        naviate("/Reviews");
      })
      .catch((error) => {
        console.error(error);
        // Handle errors here
      });
  };

  function handleApproved(reviewId) {
    // Create the data object for the review update
    const data = JSON.stringify({
      review_title: reviewData?.review_title,
      review_rating: reviewData?.review_rating,
      review_message: reviewData?.review_message,
      review_status: 1,
    });

    // Define the Axios configuration
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl}/update-review/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // Send the PUT request
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Success",
          text: "Review Approved Successfully",
          icon: "Success",
          confirmButtonText: "Close",
        });
        naviate("/Reviews");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Something went wrong",
          text: "Review not Approved",
          icon: "warning",
          confirmButtonText: "Close",
        });
      });
  }
  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Manage Reviews</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                <Link to="/Reviews">
                  <img src={Wapisi} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="dashboard-right-body customer-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="position-relative order-list-heading inner-left-head mng-reve-he d-flex align-items-center">
                <img className="invert-none" src={EditGrey} alt="" />
                <h4>Reviews</h4>
              </div>
              <div className="edit-mange-cent position-relative">
                <div className="edit-mnge-heading">
                  <h3>{reviewData?.product_name}</h3>
                </div>
                <div className="edit-mnge-star">
                  <RatingDisplay review_rating={reviewData?.review_rating} />
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid-inner text-start">
                <p>Name</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="text"
                  placeholder={reviewData?.customer_name}
                  readOnly
                />
              </div>

              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid-inner text-start">
                <p>Email</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="email"
                  placeholder={reviewData?.customer_email}
                  readOnly
                />
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid-inner text-start">
                <p>Review Title</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="text"
                  placeholder={reviewData?.review_title}
                  readOnly
                />
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid-inner mb-2 text-start h-100">
                <p>Review Message</p>
              </div>
              <div className="edit-cust-grid-inner">
                <textarea
                  placeholder={reviewData?.review_message}
                  readOnly
                ></textarea>
              </div>

              <div className="app-dec-btn">
                {reviewData?.review_status === 1 ? (
                  <button
                    className="appr"
                    style={{
                      backgroundColor: "#00dd0078",
                    }}
                    disabled
                  >
                    Approve
                  </button>
                ) : (
                  <button className="appr" onClick={handleApproved}>
                    Approve
                  </button>
                )}
                <button className="decl" onClick={handleDelete}>
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="DeleteModal" show={show} onHide={handleClose}>
        <div className="tick-modal-parent">
          <div className="modal-close-button text-end">
            <img onClick={handleClose} src={ModalCancelIcon} alt="" />
          </div>
          <div className="delete-modal-content-parent">
            <div className="Tick-modal-img">
              <img src={ModalTickIcon} alt="" />
            </div>
            <div className="dlte-modal-txt">
              <h3>Review has been Approved</h3>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditReviews;
