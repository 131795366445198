import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Eyeicn from "../../assets/imgs/eye-icon.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import Pagi from "../../assets/imgs/pagi.png";
import CustomerFilter from "./CustomerFilter";
import FilterIcon from "../../assets/imgs/filt.png";
import Pagination from "../../components/Pagi/Pagination";
import { baseUrl } from "../../utils/Function";
import axios from "axios";

function Customer() {
  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [customersCopy, setCustomersCopy] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerName, setCustomerName] = useState(null);
  const [total, setTotal] = useState(null);
  const [isfilteractive, setFilterActive] = useState(false);

  const toggleFilter = () => {
    setFilterActive(!isfilteractive);
  };
  const navigate = useNavigate();
  // pagination
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const customersToDisplay = customers.reverse().slice(startIndex, endIndex);
  const totalPages = Math.ceil(customers.reverse().length / itemsPerPage);
  //

  // filter
  const applyFilters = (orderId, name, city, dateFrom, dateTo) => {
    let filteredCustomers = [...customersCopy]; // Make a copy of the original data

    if (name) {
      filteredCustomers = filteredCustomers.filter((customer) =>
        customer.customer_name.toLowerCase().includes(name.toLowerCase())
      );
    }
    if (city) {
      filteredCustomers = filteredCustomers.filter((customer) =>
        customer.city.toLowerCase().includes(city.toLowerCase())
      );
    }
    // if (total !== null) {
    //   filteredCustomers = filteredCustomers.filter(
    //     (customer) => customer.total === total
    //   );
    // }

    // // Update the state with filtered data
    setCustomers(filteredCustomers);
  };

  //Api integration
  useEffect(() => {
    axios
      .get(`${baseUrl}/get-all-customer`)
      .then((response) => {
        setCustomers(response.data);
        setCustomersCopy(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleNavigate = (customer) => {
    navigate(`/CustomerEdit/${customer.customer_id}`, {
      state: { customerObject: customer },
    });
  };

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Customer</h2>
            </div>
            <div
              className={`reset-filt-parent d-lg-none d-block filter-opn-btn text-md-end text-end ${
                isfilteractive ? "" : ""
              }`}
            >
              <button onClick={toggleFilter}>
                <img src={FilterIcon} alt="" />
                Filter
              </button>
            </div>
            <CustomerFilter
              customerName={customerName}
              total={total}
              onFilter={applyFilters}
              RemoveOrderId="none"
              RemoveTotal="none"
              reset="none"
              filter="none"
              isfilteractive={isfilteractive}
              className={`filter-unactive d-lg-none d-block${
                isfilteractive ? "filter-unactive" : ""
              }`}
            />
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="order-list-heading inner-left-head d-flex align-items-center">
                <img src={BulletedIcon} alt="" />
                <h4>Customer List</h4>
              </div>
              <div className="main-table-parent-rsp-master">
                <div className="main-table-parent-rsp">
                  <div className="row pd-customer-row">
                    <div className="col-lg-8  ">
                      <div className="grid-sales-parent grid-customer-parent">
                        <div className="grid-sales grid-customer">
                          <div className="inner-grid-sales">
                            <p>Customer Name</p>
                          </div>
                          <div className="inner-grid-sales text-start">
                            <p className="justify-content-start">Email</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>Contact #</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>City</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>Action</p>
                          </div>
                        </div>

                        {/* map loop where customer list fetch from Api  */}

                        {loading ? (
                          <div>Loading...</div>
                        ) : (
                          customersToDisplay.map((customer) => (
                            <div
                              key={customer.customer_id}
                              className="grid-sales grid-customer grid-sales-two"
                            >
                              <div className="inner-grid-sales">
                                <p>{customer.customer_name}</p>
                              </div>
                              <div className="inner-grid-sales">
                                <p className="justify-content-start">
                                  {customer.email}
                                </p>
                              </div>
                              <div className="inner-grid-sales">
                                <p>{customer.contact}</p>
                              </div>
                              <div className="inner-grid-sales">
                                <p>{customer.city}</p>
                              </div>
                              <div className="inner-grid-sales">
                                <p>
                                  <button
                                    onClick={() => handleNavigate(customer)}
                                  >
                                    <img src={Eyeicn} alt="" />
                                  </button>
                                </p>
                              </div>
                            </div>
                          ))
                        )}

                        {/* loop end */}
                      </div>
                    </div>

                    <CustomerFilter
                      customerName={customerName}
                      total={total}
                      onFilter={applyFilters}
                      RemoveOrderId="none"
                      RemoveTotal="none"
                      reset="none"
                      filter="none"
                      className="web-cust-filter"
                    />
                  </div>
                </div>
              </div>
              <div className="main-div10 d-flex justify-content-between">
                <div className="pagi">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="txt-right-pagi">
                  <p>
                    Showing {currentPage} to {totalPages} ({totalPages} ~ Pages)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customer;
