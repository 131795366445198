import React from 'react';
import SideBar from './components/SideBar';
import SalesView from './components/SalesView';

function AdminSalesView() {
  return (
    <>
    <SideBar />
    <SalesView />
    </>
  )
}

export default AdminSalesView