import React from "react";
import SideBar from "./components/SideBar";
import Customer from "./components/Customer";

function AdminCustomer() {
  return (
    <>
      <SideBar />
      <Customer />
    </>
  );
}

export default AdminCustomer;
