import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProfilePic from "../../assets/imgs/ProfilePic.png";
import logo from "../../assets/imgs/logo.png";
import dashboardIcon from "../../assets/imgs/dashboardIcon.png";
import BoxIcon from "../../assets/imgs/BoxIcon.png";
import ReportsIcon from "../../assets/imgs/ReportsIcon.png";
import SaleIcon from "../../assets/imgs/SaleIcon.png";
import CustomerIcon from "../../assets/imgs/CustomerIcon.png";
import ShutdownIcon from "../../assets/imgs/ShutdownIcon.png";

const SideBar = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("persist:root");
  };

  const [isSidebarActive, setSidebarActive] = useState(false);

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };

  return (
    <>
      <div
        className={`first-side-bar ${
          isSidebarActive ? "sidebar-active-humburger" : ""
        }`}
      >
        <div className="side-bar-main">
          <div className="side-bar-parent">
            <div className="brn-logo d-flex justify-content-center">
              <Link to="/" className="text-center">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="user-img">
              <img src={ProfilePic} alt="" />
              <h5 className="text-theme">Admin</h5>
            </div>
            <div className="main-sidebar-list">
              <ul className="side-list-flex p-0 w-100">
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/AdminDashboard"
                    className={pathName == "/Dashboard" ? "active" : ""}
                  >
                    <img src={dashboardIcon} alt="" />
                    Dashboard
                  </Link>
                </li>
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/Product"
                    className={
                      pathName == "/Product" || pathName == "/ProductAdd"
                        ? "active"
                        : ""
                    }
                  >
                    <img src={BoxIcon} alt="" />
                    Products
                  </Link>
                </li>
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/Sales"
                    className={
                      pathName == "/Sales" || pathName == "/SalesView"
                        ? "active"
                        : ""
                    }
                  >
                    <img src={SaleIcon} alt="" />
                    Sales
                  </Link>
                </li>
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/Customer"
                    className={
                      pathName == "/Customer" || pathName == "/CustomerEdit"
                        ? "active"
                        : ""
                    }
                  >
                    <img src={CustomerIcon} alt="" />
                    Customer
                  </Link>
                </li>
                <li className="flex-list-li" aria-current="page">
                  <Link
                    to="/Reviews"
                    className={
                      pathName == "/Reviews" || pathName == "/ReviewsEdit"
                        ? "active"
                        : ""
                    }
                  >
                    <img src={ReportsIcon} alt="" />
                    Manage Reviews
                  </Link>
                </li>
              </ul>
            </div>
            <Link
              className="logout-btn start-50 position-absolute"
              to="/Login"
              onClick={handleLogout}
            >
              <img src={ShutdownIcon} alt="" />
              <span className="mx-1">Logout</span>
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`sidebar-left d-lg-none d-block ${
          isSidebarActive ? "sidebar-active-humburger" : ""
        }`}
      >
        <label className="hamburger-icon-sidebar">
          <input
            type="checkbox"
            className="hamburger-checkbox-sidebar"
            onClick={toggleSidebar}
          />
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
    </>
  );
};
export default SideBar;
