import React from "react";
import SideBar from "./components/SideBar";
import EditCustomer from "./components/EditCustomer";

function AdminCustomerEdit() {
  return (
    <>
      <SideBar />
      <EditCustomer />
    </>
  );
}

export default AdminCustomerEdit;
