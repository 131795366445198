import React from "react";
import SideBar from "./components/SideBar";
import Product from "./components/Product";

function AdminProduct() {
  return (
    <>
      <SideBar />
      <Product />
    </>
  );
}

export default AdminProduct;
