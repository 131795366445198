import React from 'react';
import '../Pagi/pagi.css';

function Pagination({ currentPage, totalPages, onPageChange }) {
  // const totalPages = ;
  const pagesToShow = 5; // Number of page numbers to show

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  // Render pagination buttons
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <>
      <div className="pagination">
        {pageNumbers.slice(currentPage - 1, currentPage - 1 + pagesToShow).map((page) => (
          <div className='pageNumber' key={page}>
            <span
              className={currentPage === page ? 'activeb' : ''}
              onClick={() => handlePageChange(page)}
              style={{
                margin: "5px",
                fontSize: "12px"
              }}
            >
              {page}
            </span>
          </div>
        ))}
      </div>
      <div className='flex' >
        <hr style={{
          width: "88px",
          marginTop: "11px",
          color: "orange",
          borderWidth: "2px",
        }} />
        <div className='arrows'>
          <span className="arrow" onClick={handlePrevPage}>
            &lt;
          </span>
          <span className="arrow" onClick={handleNextPage}>
            &gt;
          </span>
        </div>
      </div>
    </>
  );
}

export default Pagination;