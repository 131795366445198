import React from "react";
import SideBar from "./components/SideBar";
import EditReviews from "./components/EditReviews";
function AdminReviewsEdit() {
  return (
    <>
      <SideBar />
      <EditReviews />
    </>
  );
}

export default AdminReviewsEdit;
