import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Analyticsxl from "../../assets/imgs/anly-xl.png";
import Cartxl from "../../assets/imgs/cart-xl.png";
import MonthlySalesImg from "../../assets/imgs/monthly-sales.png";
import YearlySalesImg from "../../assets/imgs/yearly-sales.png";
import BulletedIcon from "../../assets/imgs/bulleted-icon.png";
import EyeIcon from "../../assets/imgs/eye-icon.png";
import Graph from "../components/Graph";
import GraphYear from "../components/GraphYear";

import { baseUrl } from "../../utils/Function";
import axios from "axios";
import Pagination from "../../components/Pagi/Pagination";

function Dashboard() {
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState([]);
  const [totalProducts, setTotalProducts] = useState([]);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-total-products`);
        // console.log(JSON.stringify(response.data));
        setTotalProducts(response.data);
      } catch (error) {
        // console.error(error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-total-orders`);
        // console.log(JSON.stringify(response.data));
        setTotalOrders(response.data);
      } catch (error) {
        // console.error(error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  // console.log(totalOrders,"totalOrderstotalOrderstotalOrders")
  // console.log(totalProducts,"totalOrderstotalOrderstotalOrders")

  // pagination
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const ordersToDisplay = orders.reverse().slice(startIndex, endIndex);
  const totalPages = Math.ceil(orders.reverse().length / itemsPerPage);
  //

  // api ka useEffect
  useEffect(() => {
    // Define your Axios request configuration
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-all-orders`,
      headers: {},
    };

    // Perform the Axios request
    axios
      .request(config)
      .then((response) => {
        setOrders(response.data);
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        // Handle any errors here
        console.log(error);
      });
  }, []);
  //

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header">
            <div className="dashboard-heading text-center">
              <h2>Dashboard</h2>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent">
              <div className="main-page-boxes-parent">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="main-page-box">
                      <div className="main-page-box-inner">
                        <img src={Analyticsxl} alt="" />
                        <h3>Total Orders</h3>
                      </div>
                      <div className="main-page-box-inner">
                        <h4>{totalOrders.totalOrders}</h4>
                        <Link to="/Sales">View More</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="main-page-box">
                      <div className="main-page-box-inner">
                        <img src={Cartxl} alt="" />
                        <h3>Total Products</h3>
                      </div>
                      <div className="main-page-box-inner">
                        <h4>{totalProducts.totalProducts}</h4>
                        <Link to="/Product">View More</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="sales-anlytics">
                      <Graph totalOrders={totalOrders.totalOrders} />
                      {/* <img src={YearlySalesImg} alt="" /> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sales-anlytics">
                      <GraphYear />
                      {/* <img src={MonthlySalesImg} alt="" /> */}
                    </div>
                  </div>
                </div>
                <div className="order-list-box">
                  <div className="order-list-box-inner">
                    <div className="order-list-heading d-flex align-items-center">
                      <img src={BulletedIcon} alt="" />
                      <h4>Order List</h4>
                    </div>
                    <div className="main-table-parent-rsp-master">
                      <div className="main-table-parent-rsp">
                        <div className="order-table-sup-parent">
                          <div className="order-table-parent">
                            <div className="order-table-inner text-center">
                              <p>Order ID</p>
                            </div>
                            <div className="order-table-inner text-center">
                              <p>Customer</p>
                            </div>
                            <div className="order-table-inner text-center">
                              <p>Email</p>
                            </div>
                            <div className="order-table-inner text-center">
                              <p>Total</p>
                            </div>
                            <div className="order-table-inner text-center">
                              <p>Date Added</p>
                            </div>
                            <div className="order-table-inner text-center">
                              <p>Action</p>
                            </div>
                          </div>

                          {ordersToDisplay.map((order, index) => (
                            <div className="order-table-parent order-table-parent-rev">
                              <div className="order-table-inner text-center">
                                <p>#{order.order_id}</p>
                              </div>
                              <div className="order-table-inner text-center">
                                <p>{order.customer_name}</p>
                              </div>
                              <div className="order-table-inner text-center">
                                <p>{order.email}</p>
                              </div>
                              <div className="order-table-inner text-center">
                                <p>${order.product_price}</p>
                              </div>
                              <div className="order-table-inner text-center">
                                <p>{order.created_at}</p>
                              </div>
                              <div className="order-table-inner text-center">
                                <p>
                                  <Link to={`/SalesView/${order.order_id}`}>
                                    <img src={EyeIcon} alt="" />
                                  </Link>
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="main-div10 d-flex justify-content-between">
                      <div className="pagi">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                      <div className="txt-center-pagi">
                        <p style={{ color: "white" }}>
                          Showing {currentPage} to {totalPages} ({totalPages} ~
                          Pages)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
