// cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [], // Store items in an array
    cartCount: 0, // Initialize cart count
  },
  reducers: {
    addToCart: (state, action) => {
      // Check if the item is already in the cart
      const existingItem = state.items.find(item => item.id === action.payload.id);

      if (existingItem) {
        // If the item exists in the cart, increase its quantity
        existingItem.quantity += 1;
      } else {
        // If the item is not in the cart, add it with quantity 1
        state.items.push({ ...action.payload, quantity: 1 });
      }

      // Update the cart count
      state.cartCount += 1;
    },
    removeFromCart: (state, action) => {
      const existingItem = state.items.find(item => item.id === action.payload.id);

      if (existingItem) {
        // If the item exists in the cart and its quantity is greater than 1, decrement its quantity
        if (existingItem.quantity > 1) {
          existingItem.quantity -= 1;
        } else {
          // If the quantity is 1, remove the item from the cart
          state.items = state.items.filter(item => item.id !== action.payload.id);
        }

        // Update the cart count
        state.cartCount -= 1;
      }
    },
  },
});

export const { addToCart, removeFromCart } = cartSlice.actions;
export default cartSlice.reducer;
