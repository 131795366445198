import React from "react";
import { Link } from "react-router-dom";
import Wapisi from "../../assets/imgs/ret-icn.png";
import EditGrey from "../../assets/imgs/edit-grey.png";
import { useLocation } from 'react-router-dom';


function EditCustomer() {
  const location = useLocation();
  const { customerObject } = location.state;

  console.log(customerObject.country , " country -----")

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Customer</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                <Link to="/Customer">
                  <img src={Wapisi} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="dashboard-right-body customer-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="order-list-heading inner-left-head d-flex align-items-center">
                <img className="invert-none" src={EditGrey} alt="" />
                <h4>Edit Customer</h4>
              </div>
              <div className="order-list-heading inner-left-head cust-det-head">
                <h4>Customer Details</h4>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>Full Name</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input type="text" placeholder={customerObject.customer_name} readOnly/>
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>Contact #</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input type="number" placeholder={customerObject.contact} readOnly />
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>Email</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input type="email" placeholder={customerObject.email} readOnly />
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>Billing Address</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input
                    type="text"
                    placeholder={customerObject.billing_address}
                    readOnly />
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>country</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input type="text" placeholder={customerObject.country} readOnly />
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>City</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input type="text" placeholder={customerObject.city} readOnly />
                </div>
              </div>
              <hr className="hr-edit-cust" />
              <div className="edit-cust-grid">
                <div className="edit-cust-grid-inner text-end">
                  <p>Post Code</p>
                </div>
                <div className="edit-cust-grid-inner">
                  <input type="text" placeholder={customerObject.postal_code} readOnly />
                </div>
              </div>
              <hr className="hr-edit-cust" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCustomer;
