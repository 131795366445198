import React from "react";
import SideBar from "./components/SideBar";
import AddProduct from "./components/AddProduct";

function AdminProductAdd() {
  return (
    <>
      <SideBar />
      <AddProduct />
    </>
  );
}

export default AdminProductAdd;
