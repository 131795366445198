import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import SecTwoImgOne from "../assets/imgs/sec-two-indone.png";
import SecTwoImgTwo from "../assets/imgs/sec-two-indtwo.png";
import SecTwoImgThree from "../assets/imgs/sec-two-indthree.png";

function SectionTwo() {
  const [sliderSettings] = useState({
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  return (
    <>
      <section className="sec-two-ind">
        <div className="container-custom">
          <div className="sec-two-ind-heading">
            <h2>Featured Services</h2>
          </div>
          <Slider {...sliderSettings}>
            <div className="slider-item-parent slider-item-parent-one">
              <img src={SecTwoImgOne} alt="" />
            </div>
            <div className="slider-item-parent slider-item-parent-two">
              <img src={SecTwoImgTwo} alt="" />
            </div>
            <div className="slider-item-parent slider-item-parent-three">
              <img src={SecTwoImgThree} alt="" />
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
}

export default SectionTwo;
