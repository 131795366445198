import React from 'react';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
import Contact from '../../components/Contact';
import Footer from '../../components/Footer';

function ContactPage() {
  return (
    <>
    <Header />
    <Banner text="Contact Us" />
    <Contact />
    <Footer />
    </>
  )
}

export default ContactPage