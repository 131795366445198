import React from "react";
import Banner from "../../components/Banner";

function ThankYouPage() {
  return (
    <>
      <Banner text="Thank You For Your Order" para="Your download will commence automatically, and a download link has also 
been sent to your registered email address." span="image" button="Back to ICGSMS" cartIcon="cart" classthank="avail"/>
    </>
  );
}

export default ThankYouPage;
