import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/style.css";
import "./assets/css/webresponsive.css";
import "./assets/css/admin.css";
import "./assets/css/adminresponsive.css";
import { Routes, Route, BrowserRouter, useParams } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Shop from "./Pages/Shop/Shop";
import ShoppingCart from "./Pages/ShoppingCart/ShoppingCart";
import About from "./Pages/About/About";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Contact from "./Pages/Contact/ContactPage";
import Terms from "./Pages/TermsAndConditions/TermsandConditions";
import Services from "./Pages/Services/Services";
import ProductPage from "./Pages/ProductPage/ProductPage";
import CheckoutWeb from "./Pages/Checkout/CheckoutWeb";
import ThankYouPage from "./Pages/ThankYouPage/ThankYouPage";
import AdminDashboard from "./Admin/AdminDashboard";
import AdminProduct from "./Admin/AdminProduct";
import AdminProductAdd from "./Admin/AdminProductAdd";
import AdminCustomer from "./Admin/AdminCustomer";
import AdminCustomerEdit from "./Admin/AdminCustomerEdit";
import AdminManageReviews from "./Admin/AdminManageReviews";
import AdminReviewsEdit from "./Admin/AdminReviewsEdit";
import { ProtectedRoute } from "./Admin/components/ProtectedRoute";
import AdminLogin from "./Admin/AdminLogin";
import AdminSales from "./Admin/AdminSales";
import AdminSalesView from "./Admin/AdminSalesView";

function App() {
  const { id } = useParams();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Terms&Conditions" element={<Terms />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Login" element={<AdminLogin />} />
        <Route path="/Shop" element={<Shop />} />
        <Route path="/Cart" element={<ShoppingCart />} />
        <Route path={`/SingleProduct/:id`} element={<ProductPage />} />
        <Route path="/Checkout" element={<CheckoutWeb />} />
        <Route path="/ThankYou" element={<ThankYouPage />} />

        {/* protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/AdminDashboard" element={<AdminDashboard />} />
          <Route path="/Product" element={<AdminProduct />} />
          <Route path="/ProductAdd/:id" element={<AdminProductAdd />} />
          {/* Conditional Route without "id" parameter */}
          {id ? null : (
            <Route path="/ProductAdd/*" element={<AdminProductAdd />} />
          )}
          <Route path="/Customer" element={<AdminCustomer />} />
          <Route path="/CustomerEdit/:id" element={<AdminCustomerEdit />} />
          <Route path="/Reviews" element={<AdminManageReviews />} />
          <Route path="/Sales" element={<AdminSales />} />
          <Route path="/SalesView" element={<AdminSalesView />} />
          <Route path="/ReviewsEdit/:id" element={<AdminReviewsEdit />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
