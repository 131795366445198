import React from 'react';
import LoginAdmin from './components/LoginAdmin';

function AdminLogin() {
    return (
        <>
            <LoginAdmin />
        </>
    )
}

export default AdminLogin