import React from 'react'
import SideBar from './components/SideBar';
import Sales from './components/Sales';

function AdminSales() {
  return (
    <>
    <SideBar />
    <Sales />
    </>
  )
}

export default AdminSales