import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import ShopSectionOne from "../../components/ShopSectionOne";

function Shop() {
  return (
    <>
      <Header />
      <Banner text="Shop" />
      <ShopSectionOne />
      <Footer />
    </>
  );
}

export default Shop;
