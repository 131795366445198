import React, { useEffect, useState } from 'react'
import Bannervid from '../../assets/vid/Banner.mp4';
import LoginLogo from "../../assets/imgs/Loginlogo.png";
import Envelope from "../../assets/imgs/envelope.png";
import Lock from "../../assets/imgs/lock.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../utils/Function'
import Swal from 'sweetalert2';



function LoginAdmin() {

  //states
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [show, setShow] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState(false);


  //variables
  const navigate = useNavigate()

  // retrive remeber me email
  useEffect(() => {
    // Retrieve the email from local storage when the component mounts
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
    
    // Check the rememberMe checkbox if it was checked when the user last logged in
    const storedRememberMe = localStorage.getItem('myapp-rememberMe');
    if (storedRememberMe) {
      setRememberMe(true);
    }
  }, []);

  // Login Functionality
  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${baseUrl}/login`, {
        email: email,
        password: password,
      });
  
      if (response.status === 200) {
        // Assuming you receive a successful response with a token
        const token = response.data.token;
  
        // Check if the token is valid (you can implement this logic)
        if (token) {
          navigate('/AdminDashboard');
          localStorage.setItem('token', token);
  
          if (rememberMe) {
            localStorage.setItem('email', email);
            localStorage.setItem('myapp-rememberMe', true);
          } else {
            localStorage.removeItem('email'); // Remove the 'email' key from local storage
            localStorage.removeItem('myapp-rememberMe'); // Remove the 'myapp-rememberMe' key from local storage
          }
        } else {
          // Show an error popup for an invalid token
          Swal.fire({
            title: 'Login Failed',
            text: 'Invalid Token',
            icon: 'error',
            confirmButtonText: 'Close',
          });
        }
      } else {
        // Show an error popup for a non-200 response
        Swal.fire({
          title: 'Login Failed',
          text: 'Invalid Credentials/Format',
          icon: 'error',
          confirmButtonText: 'Close',
        });
      }
    } catch (error) {
      // Show an error popup for an error in the API request
      Swal.fire({
        title: 'Login Failed',
        text: 'Invalid Credentials/Format',
        icon: 'error',
        confirmButtonText: 'Close',
      });
    }
  };


  //function
  return (
    <>
      <section className="login-section">
        <div className="login-content-parent">
          <div className="login-box">
            <div className="login-box-inner">
              <div className="login-logo text-center"><img src={LoginLogo} alt="" /></div>
              <div className="login-head-para text-center">
                <h3>Login</h3>
                <p>Welcome! Please enter your details</p>
              </div>
              <div className="input-and-rem-parent">
                <div className="input-login position-relative">
                  <span className="env-icon position-absolute">
                    <img src={Envelope} alt="" />
                  </span>
                  <input type="email" placeholder="Email Address" value={email} onChange={(e) => {
                    setEmail(e.target.value)
                  }} />
                </div>
                <div className="input-login position-relative">
                  <span className="lock-icon position-absolute">
                    <img src={Lock} alt="" />
                  </span>
                  <input
                    type={show ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                      setShowPassword ? setShow(false) : setShow(true);
                    }} />
                  <FontAwesomeIcon className="eye-icon" onClick={() => {
                    show ? setShow(false) : setShow(true);
                  }}
                    icon={show ? faEyeSlash : faEye} />
                </div>
                <div className="remem-me">
                  <input type="checkbox" checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)} />
                  <span>Remember Me</span>
                </div>
              </div>
              <div className="login-btn">
                <button className='LoginButton' onClick={handleSubmit}>Login</button>
              </div>
            </div>
          </div>
        </div>
        <video src={Bannervid} loop muted autoPlay className="background-video-login"></video>
      </section>
    </>
  )
}

export default LoginAdmin