import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/Function";

function YourComponent(props) {
  console.log(props)


  const productIds = props.Array ? props.Array.split(",") : [];
  const firstElement = productIds.length > 0 ? parseInt(productIds[0]) : 0; // Parsing as an integer// Convert to an integer
  const secondElement = productIds.length > 1 ? parseInt(productIds[1]) : 0; // Parsing as an integer
  const thirdElement = productIds.length > 2 ? parseInt(productIds[2]) : 0;



  const [availableProducts, setAvailableProducts] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(Number(props?.Array?.split(',')[0]));
  const [selectedDataId, setSelectedDataId] = useState(Number(props?.Array?.split(',')[1]));
  const [selectedDataId3, setSelectedDataId3] = useState(Number(props?.Array?.split(',')[2]));

  // console.log("WTF", selectedProductId, typeof Number(props?.Array?.split(',')[0]), Number(props?.Array?.split(',')[0]))
  useEffect(() => {
    setSelectedProductId(Number(props?.Array?.split(',')[0]))
    setSelectedDataId(Number(props?.Array?.split(',')[1]))
    setSelectedDataId3(Number(props?.Array?.split(',')[2])) 
  }, [props.Array])

  useEffect(() => {
    // console.log("eree", props.Array)
    const token = localStorage.getItem('token');

    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      axios
        .get(`${baseUrl}/get-all-products`)
        .then((response) => {
          // console.log(response, "res" )
          setAvailableProducts(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error('No token available. Handle unauthorized access here.');
    }
  }, []);

  const handleProductSelect = (e) => {
    const selectedId = e.target.value;
    setSelectedProductId(selectedId);
  };
  const handleDataSelect = (e) => {
    const selectedId2 = e.target.value;
    setSelectedDataId(selectedId2);
  };
  const handleDataSelect3 = (e) => {
    const selectedId3 = e.target.value;
    setSelectedDataId3(selectedId3);
  };


  useEffect(() => {

    const filteredProducts = availableProducts.filter(
      (product) => product.product_id !== selectedProductId
    );              
                    
    
    // const relatedProducts = `${selectedProductId}, ${selectedDataId}, ${selectedDataId3}`;
    const relatedProducts = `${String(selectedProductId)}, ${String(selectedDataId)}, ${String(selectedDataId3)}`
    props.setRelatedProducts(relatedProducts)
    // console.log(relatedProducts)
    // console.log(selectedProductId, selectedDataId, selectedDataId3, relatedProducts)

  }, [selectedProductId, selectedDataId, selectedDataId3])

  // Filter availableProducts to exclude the selected product







  return (
    <div className="input-pr-parent-sup">
      <div className="input-pr-parent input-pr-parent-pd">
        <div className="text-input-pr d-flex align-items-center justify-content-end">
          <h4>Product 01</h4>
        </div>
        <div className="input-pr">
          <select onChange={handleProductSelect} value={selectedProductId}>
            <option value="">Select a product</option>
            {availableProducts.map((product) => (
              <option key={product.product_id} value={product.product_id}>
                {product.product_name}
                {/* {console.log( "product 1 id ===>", selectedProductId)} */}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="input-pr-parent input-pr-parent-pd">
        <div className="text-input-pr d-flex align-items-center justify-content-end">
          <h4>Product 02</h4>
        </div>
        <div className="input-pr">
          <select onChange={handleDataSelect} value={selectedDataId}>
            <option value="">Select data</option>
            {selectedProductId && availableProducts.filter((available) => available.product_id != selectedProductId).map((product) => (
              <option key={product.product_id} value={product.product_id}>
                {product.product_name}
                {/* {console.log("product2 id ===> ",selectedDataId)} */}

              </option>
            ))}
          </select>
        </div>
      </div>
      {(
        <div className="input-pr-parent input-pr-parent-pd">
          <div className="text-input-pr d-flex align-items-center justify-content-end">
            <h4>Product 03</h4>
          </div>
          <div className="input-pr">
            <select onChange={handleDataSelect3} value={selectedDataId3}>
              <option value="">Select data</option>
              {selectedDataId && availableProducts.filter((available) => available.product_id != selectedProductId && available.product_id != selectedDataId).map((product) => (
                <option key={product.product_id} value={product.product_id}>
                  {product.product_name}
                  {/* {console.log("product 3 id ===> ", selectedDataId3)} */}

                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
}

export default YourComponent;



{/* {(
    <div className="input-pr-parent-sup">
    <div className="input-pr-parent input-pr-parent-pd">
        <div className="text-input-pr d-flex align-items-center justify-content-end">
        <h4 onChange={handleDataSelect} value={selectedDataId}  >Select Data</h4>
        </div>
        <div className="input-pr">
        <select>
            <option value="">Select data</option>
            {selectedProductId && availableProducts.filter((available) => available.product_id != selectedProductId).map((product) => (
            <option key={product.product_id} value={product.product_id}>
                {product.product_name}
                {console.log(selectedDataId, "data   id")}

            </option>
            ))}
        </select>
        </div>
    </div>
    </div>
)}
*/}