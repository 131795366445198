import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListArrow from "../assets/imgs/serv-list-arrow.png";
function ProjectTab() {

    return (
        <>
            <section className="project-tabs-section">
                <div className="container-custom">
                    <div className="project-tabs-content-parent">
                        <div className="project-tabs-heading">
                            <h2>Our Project</h2>
                        </div>
                        <div className="project-tabs-parent">
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="home" title="Oil & Gas Onshore Experience">
                                    <div className="project-tab-right-parent">
                                        <div className="project-tab-right-inner">
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Woodside – LNG Train IV Project</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Woodside – Train V Project</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Woodside – Vapour Return Line Project
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Woodside – Pluto LNG Project</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Chevron – Wheatstone LNG Project</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Chevron – Gorgon LNG Project</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Inpex – Ichthys LNG Project</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Santos – Roma GLNG Upstream Project</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="profile" title="Oil & Gas Offshore Experience">
                                    <div className="project-tab-right-parent">
                                        <div className="project-tab-right-inner">
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>ExxonMobil – PNG LNG Project</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="contact" title="Mining Resources Experience" >
                                    <div className="project-tab-right-parent">
                                        <div className="project-tab-right-inner">
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>BHP – Ravensthorpe Nickel</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>BHP – Nickel West
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>BHP – South flank & Mining Area C
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>BHP – Olympic Dam
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>BHP – Jimblebar
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>BHP – Yandi
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>BHP – Ports (Nelson & Finucane Island)
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>RTIO – Hope Downs 1
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>RTIO – Hope Downs 4
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>RTIO – Nammuldi
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>RTIO – Yandicoogina
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>RTIO – West Angela’s
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>RTIO – Ti Tree Rail Expansion
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>FMG – Port (Anderson Point)
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>FMG – Cloudbreak
                                                </p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Hancock Prospecting – Roy Hill
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="manu" title="Manufacturing Experience" >
                                    <div className="project-tab-right-parent">
                                        <div className="project-tab-right-inner">
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Fleetwood Building Solutions</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Carter Holt Harvey</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>BE&K Bechtel</p>
                                            </div>

                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="logi" title="Logistics" >
                                    <div className="project-tab-right-parent">
                                        <div className="project-tab-right-inner">
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>NQX Express</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Kwik Logistics</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="renew" title="Renewable Energy" >
                                    <div className="project-tab-right-parent">
                                        <div className="project-tab-right-inner">
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Synergy - Kwinana Battery Energy Storage System 1 Project.</p>
                                            </div>
                                            <div className="project-tab-list">
                                                <p><span><img src={ListArrow} alt="" /></span>Synergy - Kwinana Battery Energy Storage System 2 Project.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectTab