import React from "react";
import SideBar from "./components/SideBar";
import Dashboard from "./components/Dashboard";

export default function AdminDashboard() {
  console.log("Hello =>>>",localStorage.getItem("token"))
  return (
    <>
      <SideBar />
      <Dashboard />
    </>
  );
}
