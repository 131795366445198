import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../utils/Function";
import { CartContext } from "../Cart/Cart";
import Stripe from "../Pages/Checkout/Stripe";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CirclesWithBar } from "react-loader-spinner";

function CheckoutMain() {
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [enablePaynow, setEnablePaynow] = useState(false);

  const { cartItems, getCartTotal, decrementQuantity, incrementQuantity } =
    useContext(CartContext);
  const productDataArray = [];

  const [formData, setFormData] = useState({
    customer_name: "",
    contact: "",
    email: "",
    billing_address: "",
    country: "",
    city: "",
    postal_code: "",
    total_amount: getCartTotal(),
    order_items: productDataArray,
  });

  const MySwal = withReactContent(Swal);

  const isFullNameValid = (fullName) => {
    const fullNamePattern = /^[A-Za-z\s]+$/;
    return fullNamePattern.test(fullName);
  };

  useEffect(() => {
    const { customer_name, contact, email, billing_address } = formData;

    if (!isFullNameValid(customer_name)) {
      setEnablePaynow(false);
      setError("Please enter a valid full name with only letters and spaces.");
    } else if (!customer_name || !contact || !email || !billing_address) {
      setEnablePaynow(false);
      setError("");
    } else {
      setEnablePaynow(true);
      setError("");
    }
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "contact") {
      const truncatedValue = value.slice(0, 15);
      setFormData({
        ...formData,
        [name]: truncatedValue,
      });
    }
    if (name === "postal_code") {
      const truncatedValue = value.slice(0, 8);
      setFormData({
        ...formData,
        [name]: truncatedValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));

  // Extract gallery_image values into a new array
  const galleryImages = storedCartItems?.map((item) => item.gallery_image);
  console.log(galleryImages, "galleryyyyy");

  const handleDownloadAll = () => {
    // Loop through the galleryImages array and initiate download for each image
    galleryImages.forEach((relativeImageUrl, index) => {
      const imageUrl = `${baseUrl}/${relativeImageUrl}`; // Combine with the remote server URL
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = "test.pdf"; // Set a unique name for each image
      link.target = "_blank"; // Open the link in a new tab/window (optional)
      // link.rel = 'noopener noreferrer';
      link.click();
    });
  };

  const handlePaymentDone = () => {
    setLoader(false);
  };
  const handlePaymentPending = () => {
    setLoader(true);
  };
  return (
    <>
      {/* for loader */}
      {loader ? (
        <section className="checkout-main-sec">
          <div className="container-custom">
            <div className="checkout-content-parent">
              <div className="checkout-row-parent">
                <div className="row">
                  <div className="order-summary-parent">
                    <div className="order-sum-heading text-end">
                      <h2>Order Summary</h2>
                    </div>
                    <div className="grid-cart-super-parent">
                      <div className="grid-cart-parent">
                        <div className="grid-cart-inner">
                          <p>Product</p>
                        </div>
                        <div className="grid-cart-inner text-center">
                          <p>Quantity</p>
                        </div>
                        <div className="grid-cart-inner text-end">
                          <p>Price</p>
                        </div>
                      </div>
                      <div className="total-cart-page">
                        <h4 className="total-cart-one">Total :</h4>
                        <h5 className="total-cart-two">$ {getCartTotal()}</h5>
                      </div>
                    </div>
                    <div
                      className="col-lg-5"
                      style={{
                        marginLeft: "30rem",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CirclesWithBar
                        height="100"
                        width="100"
                        color="#f4c910"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        outerCircleColor="#776310"
                        innerCircleColor=""
                        barColor=""
                        ariaLabel="circles-with-bar-loading"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="checkout-main-sec">
          <div className="container-custom">
            <div className="checkout-content-parent">
              <div className="checkout-row-parent">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="checkout-input-parent">
                      <form>
                        <div className="checkout-inner">
                          <label htmlFor="chk1">
                            Full Name
                            <span className="checkout-inner-span">*</span>
                          </label>
                          <input
                            type="text"
                            id="chk1"
                            name="customer_name"
                            value={formData.customer_name}
                            onChange={handleInputChange}
                          />
                          {formData.customer_name ? (
                            <p style={{ color: "red" }}>{error}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="checkout-inner">
                          <label htmlFor="chk2">
                            Contact #
                            <span className="checkout-inner-span">*</span>
                          </label>
                          <input
                            type="number"
                            id="chk2"
                            name="contact"
                            value={formData.contact}
                            maxLength={15}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="checkout-inner">
                          <label htmlFor="chk3">
                            Email Address
                            <span className="checkout-inner-span">*</span>
                            <sup>
                              ( Enter your valid email address, as you will
                              receive your form on this email.)
                            </sup>
                          </label>
                          <input
                            type="email"
                            id="chk3"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="checkout-inner">
                          <label htmlFor="chk4">
                            Billing Address
                            <span className="checkout-inner-span">*</span>
                          </label>
                          <textarea
                            id="chk4"
                            name="billing_address"
                            value={formData.billing_address}
                            onChange={handleInputChange}
                          ></textarea>
                        </div>
                        <div className="checkout-inner">
                          <label htmlFor="chk6">City</label>
                          <input
                            type="text"
                            id="chk6"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                          />{" "}
                        </div>
                        <div className="checkout-inner">
                          <label htmlFor="chk7">Post Code</label>
                          <input
                            type="text"
                            id="chk7"
                            name="postal_code"
                            value={formData.postal_code}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="checkout-inner">
                          <label htmlFor="chk5">Country</label>
                          <input
                            type="text"
                            id="chk5"
                            name="country"
                            value={formData.country}
                            onChange={handleInputChange}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="order-summary-parent">
                      <div className="order-sum-heading text-end">
                        <h2>Order Summary</h2>
                      </div>
                      <div className="grid-cart-super-parent">
                        <div className="grid-cart-parent">
                          <div className="grid-cart-inner">
                            <p>Product</p>
                          </div>
                          <div className="grid-cart-inner text-center">
                            <p>Quantity</p>
                          </div>
                          <div className="grid-cart-inner text-end">
                            <p>Price</p>
                          </div>
                        </div>
                        {cartItems?.map((item) => {
                          const productData = {
                            product_id: item.product_id,
                            quantity: item.quantity,
                            price: item.sale_price,
                          };

                          productDataArray.push(productData);

                          return (
                            <div className="grid-cart-parent">
                              <div className="grid-cart-inner">
                                <div className="cart-product-description">
                                  <img
                                    src={`${baseUrl}/${item.product_image}`}
                                    alt=""
                                  />
                                  <h4 className="cart-resuse-text">
                                    {item.product_name}
                                  </h4>
                                </div>
                              </div>
                              <div className="grid-cart-inner text-center">
                                <div id="field1">
                                  <input
                                    type="number"
                                    value={item.quantity}
                                    className="quantity input-add-prdct w-100"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="grid-cart-inner cart-sec-price text-end">
                                <h4 className="cart-resuse-text">
                                  ${item.sale_price * item.quantity}
                                </h4>
                              </div>
                            </div>
                          );
                        })}
                        <div className="total-cart-page">
                          <h4 className="total-cart-one">Total :</h4>
                          <h5 className="total-cart-two">$ {getCartTotal()}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="check-out-para">
                <p>
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our
                  <Link to="/PrivacyPolicy">Privacy Policy.</Link>
                </p>
              </div>
              <div className="checkout-page-btn d-lg-flex d-block justify-content-between">
                <Link to="/Cart" className="position-relative">
                  BACK TO CART
                </Link>

                {enablePaynow && (
                  <div className="stripe-btn-parent">
                    <Stripe
                      handleDownloadAll={handleDownloadAll}
                      handlePaymentDone={handlePaymentDone}
                      handlePaymentPending={handlePaymentPending}
                      formData={formData}
                      totalAmount={getCartTotal()}
                      productData={productDataArray}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default CheckoutMain;
