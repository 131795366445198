import { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : []
  );

  const [totalQuantity, setTotalQuantity] = useState(0);

  const addToCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.product_id === item.product_id);
    if (!isItemInCart) {
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    } else {
    }
    setTotalQuantity(totalQuantity + 1);
  };

  const incrementQuantity = (item) => {
    setCartItems(
      cartItems.map((cartItem) =>
        cartItem.product_id === item.product_id
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      )
    );
    setTotalQuantity(totalQuantity + 1);
  };


  const decrementQuantity = (item) => {
    const updatedCartItems = cartItems.map((cartItem) =>
      cartItem.product_id === item.product_id
        ? { ...cartItem, quantity: cartItem.quantity - 1 }
        : cartItem
    );

    const filteredCartItems = updatedCartItems.filter(
      (cartItem) => cartItem.quantity > 0
    );

    setTotalQuantity(totalQuantity - 1);
    setCartItems(filteredCartItems);
  };

  const updateQuantity = (item, quantity) => {
    let qty = 0

    const itemInCart = cartItems.find((cartItem) => cartItem.product_id === item.product_id);

    if(itemInCart){
      setCartItems(
        cartItems.map((cartItem) => {
          if(cartItem.product_id === item.product_id){
            qty = quantity - cartItem.quantity
          }
          
          return (cartItem.product_id === item.product_id
            ? { ...cartItem, quantity: quantity }
            : cartItem)
        })
      )
    }else{
      setCartItems([...cartItems, { ...item, quantity: quantity }])
    }

    setTotalQuantity(totalQuantity + qty)
  }

  const CancelProduct = (item) => {
    const updatedCartItems = cartItems.filter(
      (cartItem) => cartItem.product_id !== item.product_id
    );
  
    setTotalQuantity(totalQuantity - item.quantity);
    setCartItems(updatedCartItems);
  };


  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.product_id === item.product_id);

    if (isItemInCart.quantity !== 0) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.product_id === item.product_id
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        )
      );
    setTotalQuantity(totalQuantity - 1);

      // setCartItems(cartItems.filter((cartItem) => cartItem.product_id !== item.product_id));
    } else {
      
    }
  };


  const getProductById = (productId) => {
    const product = cartItems.find((item) => item.product_id === productId);
    console.log({product, cartItems})
    return product || null; // Return null if the product is not found
  };

  const getCartTotal = () => {
    const value = cartItems.reduce(
      (total, item) => total + item.sale_price * item.quantity,
      0
    );

    return value;
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
  }, []);

  useEffect(() => {
    const newTotalQuantity = cartItems.reduce(
      (total, item) => total + item.quantity,
      0
    );
    setTotalQuantity(newTotalQuantity);
  }, [cartItems]);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        totalQuantity,
        getProductById,
        getCartTotal,
        decrementQuantity,
        updateQuantity,
        CancelProduct,
        incrementQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};