import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };

  return (
    <header>
      <nav className={`sidebar ${isSidebarActive ? "sidebar-active" : ""}`}>
        <div className="inner-sidebar-parent">
          <div
            className={
              location.pathname == "/"
                ? "inner-sidebar side-active"
                : "inner-sidebar"
            }
          >
            <Link to="/">Home</Link>
          </div>
          <div
            className={
              location.pathname == "/About"
                ? "inner-sidebar side-active"
                : "inner-sidebar"
            }
          >
            <Link to="/About">About</Link>
          </div>
          <div
            className={
              location.pathname == "/Shop"
                ? "inner-sidebar side-active"
                : "inner-sidebar"
            }
          >
            <Link to="/Shop">Shop</Link>
          </div>
          <div
            className={
              location.pathname == "/Services"
                ? "inner-sidebar side-active"
                : "inner-sidebar"
            }
          >
            <Link to="/Services">Services</Link>
          </div>
          <div
            className={
              location.pathname == "/Contact"
                ? "inner-sidebar side-active"
                : "inner-sidebar"
            }
          >
            <Link to="/Contact">Contact</Link>
          </div>
        </div>
      </nav>
      <div class="header-left d-lg-none d-md-none d-block">
        <label className="hamburger-icon">
          <input
            type="checkbox"
            class="hamburger-checkbox"
            onClick={toggleSidebar}
          />
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
    </header>
  );
};

export default Header;
