import React, { useEffect } from "react";
import RevFilter from "../assets/imgs/rev-filter.png";
import RevEdit from "../assets/imgs/edit-black.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import ModalCancelImg from "../assets/imgs/modal-cancel.png";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { baseUrl } from "../utils/Function";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { RatingDisplay } from "../Admin/components/ReviewComponent/Review";

function ProductReview({ productId }) {

  const [filter, setFilter] = useState(-1)
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [productData, setProductData] = useState({});
  const [allReviews, setAllReviews] = useState({});
  const [allReviewsShown, setAllReviewsShown] = useState({});
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [reviewTitle, setReviewTitle] = useState('');
  const [rating, setRating] = useState(0); // Initialize with an initial rating
  const [reviewMessage, setReviewMessage] = useState('');
  const [reviewname, setReviewName] = useState();
  const [sortingOption, setSortingOption] = useState("Most Recent");
  const [sortedReviews, setSortedReviews] = useState([]);


  useEffect(() => {
    setId(productId)
  }, [])



  // get all reviews ~ api
  useEffect(() => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-review-by-product-id/${id}`, // Use the 'id' parameter
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setAllReviews(response.data)
        console.log(JSON.stringify(response.data));
        // Handle the response data here, e.g., set it in state
      })
      .catch((error) => {
        console.log(error);
        // Handle errors here
      });
  }, [id]);




  // get rating by id ~ api
  useEffect(() => {

    const token = localStorage.getItem('token');
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-product-rating-ById/${productId}`,

    };

    axios.request(config)
      .then((response) => {
        setProductData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [productId]);

// poup upp..
  const MySwal = withReactContent(Swal);
  const handleSuccess = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Review created successfully',
      time: 2000,
    });
  };
  const handleFailure = () => {
    MySwal.fire({
      icon: 'error',
      title: 'Review is not added',
      time: 2000,
    });
  };


// create review button 
  const handleFunction = () => {

    const requestData = {
      product_id: productId, // You can replace this with the actual product ID
      review_title: reviewTitle,
      review_rating: rating,
      review_message: reviewMessage,
      customer_name: reviewname,
      customer_email: email
    };

    axios
      .post(`${baseUrl}/create-review`, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleSuccess()
        setRating("")
        setReviewMessage("")
        setReviewTitle("")
        setEmail("")
        setReviewName("")
        setShow(false)
      })
      .catch((error) => {
        handleFailure()
        console.error(error);
      });
  };

  const handleClose = () => {
    setShow(false)
  };
  const handleShow = () => setShow(true);

  const handleClick = (num) => {
    setFilter(num)
  }

  const starBoxClass1 = `star-box${filter === 1 ? " star-box-clicked" : ""}`;
  const starBoxClass2 = `star-box${filter === 2 ? " star-box-clicked" : ""}`;
  const starBoxClass3 = `star-box${filter === 3 ? " star-box-clicked" : ""}`;
  const starBoxClass4 = `star-box${filter === 4 ? " star-box-clicked" : ""}`;
  const starBoxClass5 = `star-box${filter === 5 ? " star-box-clicked" : ""}`;

  const [isStarBoxesVisible, setStarBoxesVisible] = useState(false);

  const toggleStarBoxes = () => {
    setStarBoxesVisible(!isStarBoxesVisible);

    if(filter !== -1){
      setFilter(-1)
    }
  };

  const numberOfReviews = allReviews.length;
  console.log(numberOfReviews, "numberOfReviews")
  function getTimeAgo(created_at) {

    const currentDate = new Date();
    const createdAtDate = new Date(created_at);
    const timeDifference = currentDate - createdAtDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference === 1) {
      return "1 day ago";
    } else if (daysDifference <= 7) {
      return `${daysDifference} days ago`;
    } else if (daysDifference <= 14) {
      return "1 week ago";
    } else {
      return "2 weeks ago";
    }
  }
  const filteredReviews = Array.isArray(allReviews)
    ? allReviews.filter((review) => review.review_status === "1")
    : [];


    
  function filterReviews() {
  return allReviews.filter((rev) => {
    if(filter !== -1){
      return rev.review_rating === filter
    } 
    return true
  });
}
const data = filter !== -1 ? filterReviews() : allReviews


  return (
    <>
      <section className="product-review-web">
        <div className="container-custom">
          <div className="review-web-sec-content-parent">
            <div className="review-sec-heading">
              <h2>
                <span className="position-relative">Reviews ({numberOfReviews})</span>
              </h2>
            </div>
            <div className="btn-product-review-parent d-flex justify-content-between">
              <div className="btn-wrapper-filter d-flex">
                <button onClick={toggleStarBoxes}>
                  <img src={RevFilter} alt="" /> Filter
                </button>
                {isStarBoxesVisible && (
                  <div className="star-boxes-parent d-flex">
                    <div className={starBoxClass1} onClick={() => handleClick(1)}>
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                    <div className={starBoxClass2} onClick={() => handleClick(2)}>
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                    <div className={starBoxClass3} onClick={() => handleClick(3)}>
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                    <div className={starBoxClass4} onClick={() => handleClick(4)}>
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                    <div className={starBoxClass5} onClick={() => handleClick(5)}>
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                )}
              </div>
              <button onClick={handleShow}>
                <img src={RevEdit} alt="" /> Write a Review
              </button>
            </div>
            <div className="gived-reviews-parent">
              <div className="review-sub-sort d-flex justify-content-between">
                <div className="rev-sort">
                  <p className="para-rev">{numberOfReviews} Reviews</p>
                </div>
                <div className="most-recent-parent">
                  <div className="sort">
                    <p className="para-rev"></p>
                  </div>
                  <div className="recent-most">
                  </div>
                </div>
              </div>
              {console.log(sortedReviews, "sortedReviewssortedReviews")}
              {data.length > 0 ? (
                data.map((rev, index) => {
                  const timeAgo = getTimeAgo(rev.created_at);

                  return (
                      <div key={index} className="customer-review-star-parent">
                        <div className="customer-name-date d-flex justify-content-between align-items-center">
                          <p className="customer-name para-rev">{rev.customer_name}</p>
                          <p className="customer-date">{timeAgo}</p> {/* Display timeAgo here */}
                        </div>
                        <div className="rating-star-customer-rev">
                          <RatingDisplay review_rating={rev.review_rating} />
                        </div>
                        <div className="customer-review-in-yell">
                          <p className="para-rev">{rev.review_title}</p>
                        </div>
                        <div className="customer-review">
                          <p className="customer-desc-para">
                            {rev.review_message}
                          </p>
                        </div>
                      </div>
                    );
                })
              ) : (
                <p>No reviews available.</p>
              )}
            </div>
          </div>
        </div>
      </section>
      <Modal className="modal-product-website" show={show} onHide={handleClose}>
        <div className="modal-parent-web-review-parent">
          <div className="modal-parent-web-review-inner-parent position-relative">
            <div className="cancel-btn-cust-modal">
              <img onClick={handleClose} src={ModalCancelImg} alt="" />
            </div>
            <div className="custom-web-modal-header">
              <div className="img-modal-custom-header">
                <img src={`${baseUrl}/${productData.product_image}`} alt="" />
              </div>
              <div className="content-parent-modal-website">
                <div className="cust-modal-heading-web-and-star">
                  <div className="cust-modal-heading-web">
                    <h3>{productData.product_name}</h3>
                  </div>
                  <div class="rating">
                    <input type="radio" name="rating" id="rating-55" value="5"
                      checked={rating === 5}
                      onChange={() => setRating(5)} />
                    <label htmlFor="rating-55"></label>
                    <input type="radio" name="rating" id="rating-44" value="4"
                      checked={rating === 4}
                      onChange={() => setRating(4)} />
                    <label htmlFor="rating-44"></label>
                    <input type="radio" name="rating" id="rating-33" value="3"
                      checked={rating === 3}
                      onChange={() => setRating(3)} />
                    <label htmlFor="rating-33"></label>
                    <input type="radio" name="rating" id="rating-22" value="2"
                      checked={rating === 2}
                      onChange={() => setRating(2)} />
                    <label htmlFor="rating-22"></label>
                    <input type="radio" name="rating" id="rating-11" value="1"
                      checked={rating === 1}
                      onChange={() => setRating(1)} />
                    <label for="rating-11"></label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-input-parent">
              <div className="modal-input-inner">
                <input type="text" placeholder="Name" value={reviewname} onChange={(e) => setReviewName(e.target.value)} />
              </div>
              <div className="modal-input-inner">
                <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="modal-input-inner">
                <input type="text" placeholder="Review Title" value={reviewTitle} onChange={(e) => setReviewTitle(e.target.value)} />
              </div>
              <div className="modal-input-inner">
                <textarea placeholder="Your Review" value={reviewMessage} onChange={(e) => setReviewMessage(e.target.value)}></textarea>
              </div>
            </div>
            <div className="web-modal-btm-para text-center">
              <p>
                By continuing you agree to our{" "}
                <Link to="/Terms&Conditions">Terms and Conditions</Link> and{" "}
                <Link to="/PrivacyPolicy">PrivacyPolicy</Link>
              </p>
            </div>
            <div className="agree-btn-modal-web">
              <button onClick={handleFunction}>Agree & Submit</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductReview;
