import React from "react";
import OurValuesImg from "../assets/imgs/our-values.png";
import OurValuesImgResp from "../assets/imgs/our-values-resp.png";

function OurValues() {
  return (
    <>
      <section className="our-values-sec">
        <div className="container-custom">
          <div className="content-parent-our-values">
            <div className="d-flex align-items-end">
              <div className="parent-of-heading-our-speci">
                <div className="ind-sec-one-heading">
                  <h2>Our</h2>
                </div>
                <div className="our-speci-sub-head">
                  <h2>Values</h2>
                </div>
              </div>
              <div className="parent-of-heading-our-speci w-100">
                <hr />
              </div>
            </div>
            <div className="our-values-main">
              <img className="d-lg-block d-none" src={OurValuesImg} alt="" />
              <img
                className="d-lg-none d-block our-values-main-resp-img"
                src={OurValuesImgResp}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurValues;
