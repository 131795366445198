import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Wapisi from "../../assets/imgs/ret-icn.png";
import LeftChevi from "../../assets/imgs/left-chev.png";
import EditGrey from "../../assets/imgs/edit-grey.png";
import PlusCirc from "../../assets/imgs/plus-circ.png";
import Editor from "./Editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../utils/Function";
import axios from "axios";
import DropDown from "./DropDown";
import { data } from "jquery";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function AddProduct() {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayedImage, setDispplayedImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [descrip, setDescrip] = useState();
  const [dataById, setDataById] = useState("");
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [productData, setProductData] = useState({
    productName: "",
    regularPrice: "",
    salePrice: "",
    descrip: "",
    id: "",
  });

  const navigate = useNavigate();

  // console.log(relatedProducts, `related`)
  const MySwal = withReactContent(Swal);

  // console.log(dataById)

  const { id } = useParams(); // Access the 'id' from the route parameters

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const handleSuccess = () => {
    MySwal.fire({
      icon: "success",
      title: "Product added successfully",
      time: 4000,
    });
  };
  const handleFailure = () => {
    MySwal.fire({
      icon: "error",
      title: "Product is not added",
      time: 4000,
    });
  };

  // get product by id
  useEffect(() => {
    const token = localStorage.getItem("token");

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-productById/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Make the Axios request
    axios
      .request(config)
      .then((response) => {
        // console.log(response)

        setDataById(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleUpdateData = () => {
    if (selectedImage == null) {
      console.log("No image found");
    }
    const token = localStorage.getItem("token");

    const data = new FormData();
    data.append(
      "product_name",
      productData.productName ? productData.productName : dataById.product_name
    );
    data.append(
      "regular_price",
      productData.regularPrice
        ? productData.regularPrice
        : dataById.regular_price
    );
    data.append(
      "sale_price",
      productData.salePrice ? productData.salePrice : dataById.sale_price
    );
    data.append(
      "product_description",
      productData.descrip ? productData.descrip : dataById.product_description
    );
    data.append("related_products", relatedProducts);

    if (selectedImage) {
      data.append("product_image", selectedImage);
    } else {
      data.append("product_image", dataById.product_image);
    }

    // Append the selectedFile to the form data if it exists
    if (selectedFile) {
      data.append("gallery_image", selectedFile);
      console.log(selectedFile, "With PDF");
    } else {
      data.append("gallery_image", dataById.gallery_image);
      console.log(dataById.gallery_image, "Without pdf ");
    }

    console.log(data, " on update datadata");

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl}/update-product/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        // ...data.getHeaders(), // Include the headers for FormData
      },
      data: data, // Pass the FormData object directly
    };

    axios
      .request(config)
      .then((response) => {
        MySwal.fire({
          icon: "success",
          title: "Product update successfully",
          time: 4000,
        });
        navigate("/Product");
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        handleFailure();
        // console.log(error);
      });
  };

  const handleSaveData = () => {
    const token = localStorage.getItem("token");

    const data = new FormData();
    data.append("product_name", productData.productName);
    data.append("regular_price", productData.regularPrice);
    data.append("sale_price", productData.salePrice);
    data.append("product_description", productData.descrip);
    data.append("related_products", relatedProducts);

    // Append the selectedImage to the form data if it exists
    if (selectedImage) {
      data.append("product_image", selectedImage);
    }

    // Append the selectedFile to the form data if it exists
    if (selectedFile) {
      data.append("gallery_image", selectedFile);
    }

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/create-product`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data, // No need to stringify the data in this case
    };

    axios
      .request(config)
      .then((response) => {
        MySwal.fire({
          icon: "success",
          title: "Product Added successfully",
          time: 4000,
        });
        navigate("/Product");
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        handleFailure();
        // console.log(error);
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setDispplayedImage(reader.result);
        // Store the base64 encoded image data in state
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFile(file); // Store the file object in state
    }
  };

  const handleShowFile = () => {
    alert("your file is selected");
  };

  useEffect(() => {
    // Assuming you have the file path in the `dataById.product_image`
    if (dataById.product_image) {
      // Set the file path to display the image
      setImagePath(dataById.product_image);
    }
  }, [dataById.product_image]);

  return (
    <>
      <div className="second-side-parent">
        <div className="dashboard-content-parent">
          <div className="dashboard-right-header dsh-board-inner-head d-flex justify-content-between">
            <div className="dashboard-heading text-start">
              <h2>Product</h2>
            </div>
            <div className="right-icons-parent">
              <div className="right-icn">
                <Link to="/Product">
                  <img src={Wapisi} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="dashboard-right-body">
            <div className="dashboard-right-body-inner-parent inner-pages-body">
              <div className="left-chevi">
                <Link to="/Product">
                  <img src={LeftChevi} alt="" />
                </Link>
              </div>
              <div className="order-list-heading inner-left-head pr-add-heading d-flex align-items-center">
                <img className="invert-none" src={EditGrey} alt="" />
                <h4>{id ? "Edit Product" : "Add Product"}</h4>
              </div>
              <div className="add-pr-row">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="col9inner-add-pr">
                      <div className="input-pr-parent-sup">
                        {/* <div className="input-pr-parent"> */}
                        <div className="text-input-pr d-flex align-items-center justify-content-start">
                          <h4>
                            <span>*</span>Product Name
                          </h4>
                          <br />
                        </div>
                        {/* <div className="input-pr">
                            <input
                              type="text"
                              defaultValue={dataById.product_name}
                              onChange={(e) => {
                                const inputText = e.target.value;
                                if (/^[a-zA-Z0-9]+$/.test(inputText)) {
                                  setProductData({
                                    ...productData,
                                    productName: inputText,
                                  });
                                }
                              }}
                            />
                          </div> */}
                        <div className="input-pr">
                          <input
                            type="text"
                            defaultValue={dataById.product_name}
                            onChange={(e) => {
                              setProductData({
                                ...productData,
                                productName: e.target.value,
                              });
                            }}
                          />
                        </div>
                        {/* </div> */}
                      </div>
                      <hr />
                      <div className="input-pr-parent-sup">
                        <div className=" input-pr-parent-pd">
                          <div className="text-input-pr d-flex align-items-center justify-content-start">
                            <h4>
                              <span>*</span>Regular Price
                            </h4>
                          </div>
                          <div className="input-pr">
                            <input
                              type="number"
                              defaultValue={dataById.regular_price}
                              onChange={(e) => {
                                setProductData({
                                  ...productData,
                                  regularPrice: e.target.value,
                                });
                              }}
                            />
                            {/* {console.log(productData.regularPrice, "productData.productData")} */}
                          </div>
                        </div>
                        <div className="text-input-pr d-flex align-items-center justify-content-start">
                          <h4>Sale Price</h4>
                        </div>
                        <div className="input-pr">
                          <input
                            type="number"
                            defaultValue={dataById.sale_price} // Initially, use data from the API
                            onChange={(e) => {
                              setProductData({
                                ...productData,
                                salePrice: e.target.value,
                              });
                            }}
                          />
                          {/* {console.log(productData.salePrice, "productData.productData")} */}
                        </div>
                      </div>
                      <hr />
                      <div className="input-pr-parent-sup">
                        <div className="text-input-pr d-flex align-items-center justify-content-start">
                          <h4>
                            <span>*</span>Description
                          </h4>
                        </div>
                        <div className="input-pr">
                          <textarea
                            defaultValue={dataById.product_description}
                            onChange={(e) => {
                              setProductData({
                                ...productData,
                                descrip: e.target.value,
                              });
                            }}
                          ></textarea>
                        </div>
                      </div>
                      {/* <div className="input-pr-parent">
                          <div className="text-input-pr d-flex align-items-start justify-content-end">
                            <h4>Description</h4>
                          </div>
                          <div className="input-pr">
                            <input
                              name="description"
                              defaultValue={dataById.product_description}
                              onChange={(descrip) => {
                                setDescrip(descrip)
                              }}
                            />
                          </div>
                        </div> */}
                      <hr />
                      <div className=" text-input-pr">
                        <h4>Add Related Products</h4>
                      </div>
                      <DropDown
                        setRelatedProducts={setRelatedProducts}
                        ID={id}
                        Array={dataById.related_products}
                      />
                    </div>
                    <div className="save-btn-add-pr d-flex justify-content-center">
                      {id ? (
                        <button onClick={handleUpdateData}>Update</button>
                      ) : (
                        <button onClick={handleSaveData}>Save</button>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div className="right-parent-sup">
                      <div class="right-parent">
                        <div class="image-box-parent">
                          <div class="image-box-heading">
                            <h3>Product Image ( Jpg/Png )</h3>
                          </div>
                          <div class="image-box" onclick="selectImage(this)">
                            <input
                              type="file"
                              class="image-input"
                              id="fileInput"
                              accept="image/*"
                              onChange={handleImageChange}
                            />

                            {dataById.product_image && selectedImage ? (
                              <div>
                                <img
                                  src={displayedImage}
                                  alt="Selected"
                                  width="200"
                                />
                              </div>
                            ) : dataById.product_image ? (
                              <div>
                                <img
                                  src={`${baseUrl}/${dataById.product_image}`}
                                  alt="Old"
                                  width="200"
                                />
                              </div>
                            ) : selectedImage ? (
                              <div>
                                <img
                                  src={displayedImage}
                                  alt="select hogyii"
                                  width="200"
                                />
                              </div>
                            ) : (
                              <> </>
                            )}

                            {selectedImage ? (
                              ""
                            ) : dataById.product_image ? (
                              ""
                            ) : (
                              <p>
                                <span>
                                  <img
                                    class="img-plus"
                                    src={PlusCirc}
                                    alt=""
                                    onClick={() => {
                                      document
                                        .getElementById("fileInput")
                                        .click();
                                    }}
                                  />
                                </span>
                                Upload Product Image
                              </p>
                            )}
                          </div>
                        </div>
                        <div class="prdt-upld-btn">
                          <Link
                            onClick={() => {
                              document.getElementById("fileInput").click();
                            }}
                          >
                            Upload
                          </Link>
                        </div>
                      </div>
                      <div class="right-parent">
                        <div class="image-box-parent">
                          <div class="image-box-heading">
                            <h3>Upload Document ( Pdf )</h3>
                          </div>
                          <div class="image-box" onclick="selectImage(this)">
                            <input
                              type="file"
                              class="image-input"
                              accept="application/pdf"
                              id="PdffileInput"
                              onchange="displayImage(event, this)"
                              onChange={handleFileChange}
                            />
                            {dataById.gallery_image && selectedFile ? (
                              <div>
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  size="2x"
                                  onClick={handleShowFile}
                                />
                                <label>File</label>
                              </div>
                            ) : dataById.gallery_image ? (
                              <div>
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  size="2x"
                                  src={`${baseUrl}/${dataById.gallery_image}`}
                                />
                              </div>
                            ) : selectedFile ? (
                              <div>
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  size="2x"
                                  onClick={handleShowFile}
                                />
                                <label>File</label>
                              </div>
                            ) : (
                              <></>
                            )}
                            {selectedFile ? (
                              ""
                            ) : dataById.gallery_image ? (
                              ""
                            ) : (
                              <p>
                                <span>
                                  <img
                                    class="img-plus"
                                    src={PlusCirc}
                                    alt=""
                                    onClick={() => {
                                      document
                                        .getElementById("PdffileInput")
                                        .click();
                                    }}
                                  />
                                </span>
                                Upload Document
                              </p>
                            )}
                          </div>
                        </div>
                        <div class="prdt-upld-btn">
                          <Link
                            onClick={() => {
                              document.getElementById("PdffileInput").click();
                            }}
                          >
                            Upload
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
