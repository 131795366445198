import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Privacy from "../../components/Privacy";
import Footer from "../../components/Footer";

function PrivacyPolicy() {
  return (
    <>
      <div className="privacy-overflow">
        <Header />
        <Banner text="Privacy Policy" class="not null" />
        <Privacy />
        <Footer />
      </div>
    </>
  );
}

export default PrivacyPolicy;
