import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import CheckoutMain from "../../components/CheckoutMain";
import Footer from "../../components/Footer";

function CheckoutWeb() {
  return (
    <>
      <Header />
      <Banner text="Checkout" cartIcon="cart" />
      <CheckoutMain />
      <Footer />
    </>
  );
}

export default CheckoutWeb;
