import React from 'react';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
import Footer from '../../components/Footer';
import ServicesSecOne from '../../components/ServicesSecOne';
import ServicesSecTwo from '../../components/ServicesSecTwo';
import ProjectTab from '../../components/ProjectTab';

function Services() {
    return (
        <>
            <Header />
            <Banner text="Services" />
            <ServicesSecOne />
            <ServicesSecTwo />
            <ProjectTab />
            <Footer />
        </>
    )
}

export default Services